import { useState, useCallback, useEffect } from "react";
import PartnerHeader from "../modules/HeaderPartner";
import FooterInner from "../modules/FooterInner";
import SurveyStep1 from "../modules/SurveyStep1";
import SurveyStep2 from "../modules/SurveyStep2";
import SurveyStep3 from "../modules/SurveyStep3";
import SurveyStep4 from "../modules/SurveyStep4";

import { useParams } from "react-router-dom";
import {
  axiosGetPartnerSurveyRoute,
  axiosSubmitSurveyRoute,
} from "../helpers/axios";

const SurveySteps = ({ notify, type }) => {
  const [stepNumber, setStepNumber] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [surveyData, setSurveyData] = useState(null);

  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [userDetails, setUserDetails] = useState({
    user_email: "",
  });

  const [partnerLink, setPartnerLink] = useState("");

  let { survey_name, user_email } = useParams();

  useEffect(() => {
    if (user_email) {
      setUserDetails((prev) => {
        return {
          ...prev,
          user_email,
        };
      });
    }
  }, [user_email]);

  const getSurvey = useCallback(
    async (id_survey, userDetails) => {
      try {
        let result;

        result = await axiosGetPartnerSurveyRoute(`/${id_survey}`, {
          data: {
            ...userDetails,
          },
        });

        if (result.data) {
          setSurveyData(result.data);

          if (result.data.findusersurvey) {
            let answerobj = {};

            result.data.findusersurvey.answers.forEach((answer) => {
              return (answerobj = {
                ...answerobj,
                [answer.question_id.id]: answer.response,
              });
            });

            setSurveyAnswers(answerobj);
          }
        }
      } catch (err) {
        notify(err.response.data.error.message, "error");
        console.log(err.response.data.error.message);
      }
    },
    [notify]
  );

  const submitSurveyHandler = useCallback(
    async ({ final_step = false, save_per_category = false }) => {
      if (final_step) {
        try {
          const results = await axiosSubmitSurveyRoute({
            data: {
              user_survey_id: surveyData?.findusersurvey?.id || null,
              survey_id: surveyData.id,
              answers: surveyAnswers,
              survey_type: type,
              survey_complete: final_step,
              user_email: userDetails.user_email,
            },
          });

          if (results.data) {
            // set the partner link
            setPartnerLink(
              `/partner-survey/results/${results.data.findusersurvey.anon_request}`
            );
          }
        } catch (err) {
          notify(err.response.data.error.message, "error");
          console.log(err);
        }
      }

      if (!save_per_category)
        setStepNumber((currentstep) => Number(currentstep) + 1);

      window.scrollTo(0, 0);
    },
    [surveyAnswers, surveyData, type, userDetails.user_email, notify]
  );

  const gatherSurveyAnswers = (item, selection) => {
    setSurveyAnswers((values) => ({
      ...values,
      [item.id]: selection,
    }));
  };

  useEffect(() => {
    if (!surveyData) {
      getSurvey(survey_name, userDetails);
    }
  }, [getSurvey, surveyData, survey_name, userDetails]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const logo = surveyData?.company_id?.logo?.url || "";

  const company_logo = backendUrl && logo && `${backendUrl}${logo}`;

  return (
    <div>
      <PartnerHeader
        mode={"usersurvey"}
        company_logo={company_logo}
        company_name={surveyData?.company_id?.name || ""}
      />
      {surveyData && (
        <>
          {!stepNumber && (
            <SurveyStep1
              surveyData={surveyData}
              setStepNumber={setStepNumber}
            />
          )}
          {stepNumber === 2 && (
            <SurveyStep2
              surveyData={surveyData.questions}
              surveyAnswers={surveyAnswers}
              submitSurveyHandler={submitSurveyHandler}
              gatherSurveyAnswers={gatherSurveyAnswers}
              categories={Object.keys(surveyData?.questions_sorted)}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
            />
          )}
          {stepNumber === 3 && (
            <SurveyStep3
              surveyType={type}
              surveyData={surveyData.questions}
              surveyAnswers={surveyAnswers}
              setStepNumber={setStepNumber}
              gatherSurveyAnswers={gatherSurveyAnswers}
              submitSurveyHandler={submitSurveyHandler}
            />
          )}
          {stepNumber === 4 && (
            <SurveyStep4
              partnerLink={partnerLink}
              surveyType={type}
              surveyData={surveyData}
              setStepNumber={setStepNumber}
            />
          )}
        </>
      )}

      <FooterInner />
    </div>
  );
};

export default SurveySteps;

// legacy email prompt

/* <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
  <div className="bg-white rounded-lg p-8 max-w-md">
    <h2 className="text-2xl font-semibold mb-4">
      Please provide your complete details
    </h2>
    <input
      type="text"
      placeholder="First Name"
      value={userDetails.first_name}
      onChange={(e) =>
        setUserDetails((prev) => ({
          ...prev,
          first_name: e.target.value,
        }))
      }
      className="block w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
    />
    <input
      type="text"
      placeholder="Last Name"
      value={userDetails.last_name}
      onChange={(e) =>
        setUserDetails((prev) => ({
          ...prev,
          last_name: e.target.value,
        }))
      }
      className="block w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
    />
    <input
      type="email"
      placeholder="Email"
      value={userDetails.user_email}
      onChange={(e) =>
        setUserDetails((prev) => ({
          ...prev,
          user_email: e.target.value,
        }))
      }
      className="block w-full border border-gray-300 rounded-lg px-4 py-2"
    />
    <button
      disabled={
        userDetails.first_name === "" ||
        userDetails.last_name === "" ||
        userDetails.user_email === ""
      }
      onClick={() => {
        getSurvey(survey_name, userDetails);
      }}
      className="disabled:opacity-20 mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
    >
      Submit
    </button>
  </div>
</div>; */
