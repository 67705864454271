import { useTranslation } from "react-i18next";
const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <section className="text-[13px] text-gray_1 py-6 font-inter font-medium dark:text-reverted_gray">
      <div className="container mx-auto flex flex-wrap">
        <div
          className={`self-center${
            props.align === "center"
              ? " text-center lg:w-1/3 lg:ml-[33%]"
              : " lg:w-2/3"
          }`}
        >
          {t("footer.copyright")} <span className="icon-ssl ml-4"></span>{" "}
          {t("footer.trust")}
        </div>
        <div className="lg:w-1/3 lg:text-right font-semibold self-center mt-5 lg:mt-0"></div>
      </div>
    </section>
  );
};

export default Footer;
