const Countries = ({ t }) => {
  return (
    <select
      className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-full"
      name="country"
      id="country"
      required
    >
      <option disabled selected value="">
        {t("modal.register_user.choose")}
      </option>
      <option>Afghanistan</option>
      <option>Albania</option>
      <option>Algeria</option>
      <option>Angola</option>
      <option>Argentina</option>
      <option>Armenia</option>
      <option>Australia</option>
      <option>Austria</option>
      <option>Bahrain</option>
      <option>Bangladesh</option>
      <option>Belarus</option>
      <option>Belgium</option>
      <option>Bhutan</option>
      <option>Bolivia</option>
      <option>Bosnia &amp; Herzegovina</option>
      <option>Botswana</option>
      <option>Brazil</option>
      <option>Bulgaria</option>
      <option>Cambodia</option>
      <option>Cameroon</option>
      <option>Canada</option>
      <option>Chile</option>
      <option>China</option>
      <option>Colombia</option>
      <option>Costa Rica</option>
      <option>Croatia</option>
      <option>Cuba</option>
      <option>Cyprus</option>
      <option>Czech Republic</option>
      <option>Denmark</option>
      <option>Ecuador</option>
      <option>Egypt</option>
      <option>Estonia</option>
      <option>Ethiopia</option>
      <option>Fiji</option>
      <option>Finland</option>
      <option>France</option>
      <option>Germany</option>
      <option>Ghana</option>
      <option>Greece</option>
      <option>Greenland</option>
      <option>Guinea</option>
      <option>Guyana</option>
      <option>Haiti</option>
      <option>Honduras</option>
      <option>Hong Kong</option>
      <option>Hungary</option>
      <option>Iceland</option>
      <option>India</option>
      <option>Indonesia</option>
      <option>Iran</option>
      <option>Iraq</option>
      <option>Ireland</option>
      <option>Israel</option>
      <option>Italy</option>
      <option>Japan</option>
      <option>Jersey</option>
      <option>Jordan</option>
      <option>Kazakhstan</option>
      <option>Kenya</option>
      <option>Kuwait</option>
      <option>Kyrgyzstan</option>
      <option>Latvia</option>
      <option>Lebanon</option>
      <option>Liberia</option>
      <option>Libya</option>
      <option>Lithuania</option>
      <option>Luxembourg</option>
      <option>Macedonia</option>
      <option>Madagascar</option>
      <option>Malaysia</option>
      <option>Maldives</option>
      <option>Mali</option>
      <option>Malta</option>
      <option>Mauritius</option>
      <option>Mexico</option>
      <option>Monaco</option>
      <option>Mongolia</option>
      <option>Morocco</option>
      <option>Namibia</option>
      <option>Nepal</option>
      <option>Netherlands</option>
      <option>New Zealand</option>
      <option>Nigeria</option>
      <option>North Korea</option>
      <option>Norway</option>
      <option>Oman</option>
      <option>Pakistan</option>
      <option>Panama</option>
      <option>Papua New Guinea</option>
      <option>Paraguay</option>
      <option>Peru</option>
      <option>Philippines</option>
      <option>Poland</option>
      <option>Portugal</option>
      <option>Qatar</option>
      <option>Romania</option>
      <option>Russia</option>
      <option>Rwanda</option>
      <option>Saudi Arabia</option>
      <option>Serbia</option>
      <option>Singapore</option>
      <option>Slovakia</option>
      <option>Slovenia</option>
      <option>South Africa</option>
      <option>South Korea</option>
      <option>Spain</option>
      <option>Sri Lanka</option>
      <option>Sudan</option>
      <option>Sweden</option>
      <option>Switzerland</option>
      <option>Syria</option>
      <option>Taiwan</option>
      <option>Tajikistan</option>
      <option>Tanzania</option>
      <option>Thailand</option>
      <option>Tunisia</option>
      <option>Turkey</option>
      <option>Turkmenistan</option>
      <option>Uganda</option>
      <option>Ukraine</option>
      <option>United Arab Emirates</option>
      <option>United Kingdom</option>
      <option>United States</option>
      <option>Uruguay</option>
      <option>Uzbekistan</option>
      <option>Venezuela</option>
      <option>Vietnam</option>
      <option>Yemen</option>
      <option>Zambia</option>
      <option>Zimbabwe</option>
    </select>
  );
};

export { Countries };
