import { useEffect, useState } from "react";
import Header from "../modules/Header";
import { useOutletContext } from "react-router-dom";
import FooterInner from "../modules/FooterInner";
import {
  SurveyTabs,
  SurveyResultsBox,
  SurveyTextQuestionBox,
  SurveySummaryResults,
  ModalCategories,
  ModalUserRegister,
} from "../modules/partials/survey-results-partials";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const SurveyResults = () => {
  const {
    surveyResults,
    userDetails,
    sendInvite,
    inviteEmailAddresses,
    setInviteEmailAddresses,
    frontendurl,
    sendEvent,
    updateUserDetails,
    updateSurveyHandler,
  } = useOutletContext();

  const [toggleActive, setToggleActive] = useState();
  const [expandActive, setExpandActive] = useState();
  const [showAllActive, setShowAllActive] = useState();
  const [openTab, setOpenTab] = useState("");
  const [copied, setCopied] = useState(false);

  const { t } = useTranslation();
  const radarData = {
    labels: [
      "Growth",
      "Orientation",
      "Collaboration",
      "Balance",
      "Learning",
      "Ownership",
    ],
    short_labels: ["gr", "or", "co", "ba", "ln", "op"],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "rgba(216, 240, 232, 0.7)",
          "rgba(222, 238, 249, 0.7)",
          "rgba(252, 244, 228, 0.9)",
          "rgba(229, 227, 241, 0.8)",
          "rgba(237, 228, 246, 0.8)",
          "rgba(251, 230, 237, 0.8)",
        ],
        borderWidth: 0.2,
        borderColor: [
          "#32B78B",
          "#479CDA",
          "#CE992F",
          "#3A2699",
          "#7A4CC4",
          "#E2376B",
        ],
        circular: "inner",
        borderJoinStyle: "round",
      },
    ],
  };
  var strength = { title: "", text: "", value: null, img: "" };
  var weakness = { title: "", text: "", value: null, img: "" };
  var all_sum = surveyResults?.data?.summary?.participant_complete_count;
  var start_date = new Date(surveyResults?.survey_starting_date);
  var end_date = surveyResults?.survey_closing_date
    ? new Date(surveyResults?.survey_closing_date)
    : null;
  var self_access_is_set = false;
  var show_user_registration_modal =
    userDetails === null ||
    userDetails["company_role"] === null ||
    userDetails["company_size"] === null ||
    userDetails["country"] === null ||
    userDetails["email"] === null ||
    userDetails["first_name"] === null ||
    userDetails["last_name"] === null ||
    userDetails["industry"] === null ||
    userDetails["team_size"] === null;
  const SurveySummaryResultsData = [
    {
      title: t("surveyResults.summary.leadership_elements"),
      result: surveyResults?.data?.summary?.leadership_elements_count,
    },
    {
      title: t("surveyResults.summary.participants"),
      result: surveyResults?.data?.summary?.participant_count,
    },
    {
      title: t("surveyResults.summary.skills"),
      result: surveyResults?.data?.summary?.skills_count,
    },
    {
      title: t("surveyResults.summary.answers_given"),
      result: surveyResults?.data?.summary?.answers_given_count,
    },
    {
      title: t("surveyResults.summary.total_exercises"),
      result: "108",
    },
    {
      title: t("surveyResults.summary.opinions_given"),
      result: surveyResults?.data?.summary?.opinions_given_count,
    },
    /*{
      title: t("surveyResults.summary.time_average"),
      result: "22 min",
    },*/
    {
      title: t("surveyResults.summary.completion_rate"),
      result: surveyResults?.data?.summary?.completion_rate_count,
    },
  ];
  if (surveyResults?.data?.categories) {
    radarData.datasets[0].data = surveyResults?.data?.categories?.map(
      (item, ind) => {
        if (radarData.labels[ind] !== item.title)
          //if not category isset return minimum 1
          return 1;
        else {
          var rating = 0;
          var responses = 0;
          item.subelements.map((subitem, skill_ind) => {
            subitem.subcategory.questions.map((question, skill_ind) => {
              rating += question.answer.rating ?? 0;
              responses += question.answer.possible_score_range ?? 0;
              if (question.answer.self_rating > 0) self_access_is_set = true;
              return [rating, responses];
            });
            return [rating, responses];
          });
          item.sum_rating = rating % 1 === 0 ? rating : rating.toFixed(1);
          item.sum_responses = responses;
          var percentage = (rating * 100) / responses;
          if (
            strength.value === null ||
            parseFloat(strength.value) < percentage
          )
            strength = {
              title: item.title,
              text: item.text,
              value: percentage,
              img: item.icon.url,
            };
          if (weakness.value === null || weakness.value > percentage)
            weakness = {
              title: item.title,
              text: item.text,
              value: percentage,
              img: item.icon.url,
            };
          var $return = (percentage * 5) / 100; //convert to top 5
          return $return % 1 === 0 ? $return : $return.toFixed(1);
        }
      }
    );
  }
  useEffect(() => {
    if (all_sum >= 3) {
      setOpenTab("stats");
        window.dataLayer.push({
            'event':'VirtualPageview',
            'virtualPageURL':'/survey/results?stage=complete',
            'virtualPageTitle': 'survey complete'
        });
    } else {
        window.dataLayer.push({
            'event':'VirtualPageview',
            'virtualPageURL':'/survey/results?stage=pre-invite',
            'virtualPageTitle': 'survey pre-invite'
        });
      if (
        surveyResults.invite_based === null ||
        surveyResults.invite_based === true
      ) {
        setOpenTab("invite");
      } else {
        setOpenTab("link");
      }
    }
  }, [surveyResults, all_sum]);

  return (
    surveyResults?.data && (
      <div>
            <Header username={userDetails["email"]} />
        <section
          className={`container lg:px-0 flex flex-wrap pt-[140px] pb-[60px] mx-auto lg:w-[955px]${
            all_sum < 3 || show_user_registration_modal ? " no-results" : ""
          }`}
        >
          <div className="lg:w-[calc(50%-17px)] w-full mb-[34px] self-end">
            <h2 className="title-primary mb-2">
              {t("surveyResults.page_title")}
            </h2>
            <p className="subtitle  mb-5">{t("surveyResults.page_text")}</p>
            <div className="bg-[#E9E9E9] flex flex-wrap rounded-[15px] p-6">
              {all_sum < 3 ? (
                <div className="overlay-no-answers">
                  <h3 className="title-primary-base mb-2">
                    {t("surveyResults.no_results.title")}
                  </h3>
                  <p className="subtitle mb-0">
                    {t("surveyResults.no_results.text")}
                  </p>
                </div>
              ) : (
                <>
                  <div className="sm:w-1/2 w-full text-center bg-white rounded-[15px] pt-3 pb-3 px-1 sm:mb-0 mb-4">
                    <h3 className="title-primary-sm mb-1 uppercase">
                      Results scorecard
                    </h3>
                    <PolarArea
                      className="mx-auto max-w-full p-2"
                      data={radarData}
                      options={{
                        animation: { duration: 1400 },
                        plugins: {
                          ChartDataLabels,
                          datalabels: {
                            formatter: function (value, context) {
                              return radarData.short_labels[
                                context.dataIndex
                              ].toUpperCase();
                            },
                            anchor: "end",
                            align: "center",
                            font: {
                              family: "Poppins, sans-serif",
                              weight: "bold",
                            },
                          },
                          legend: {
                            display: false,
                          },
                        },
                        clip: { left: 0, top: 0, right: 0, bottom: 0 },
                        scales: {
                          r: {
                            angleLines: {
                              display: true,
                            },
                            ticks: {
                              maxTicksLimit: 1,
                              display: false,
                            },
                            pointLabels: {
                              /* render: "label",
                               * arc: true,
                               * fontColor: "#000",
                               * position: "outside", */
                              display: false,
                              /* centerPointLabels: true, */
                              /* font: {}, */
                            },
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="sm:w-1/2 w-full pt-3">
                    <div className="lg:px-5 px-4">
                      <h3 className="title-primary-sm mb-3">
                        {t("surveyResults.strength.title")}
                      </h3>
                    </div>
                    <div className="lg:px-5 px-4 mb-7">
                      <div className="align-top inline-block">
                        <span
                          className={`icon-strength-weakness bg-${strength.title} mr-2 `}
                        >
                          <img
                            className="icon-inner-img"
                            src={imageUrl + strength.img}
                            alt={strength.title}
                          />
                        </span>
                      </div>
                      <div className="align-middle inline-block w-2/3">
                        <h4 className="title-base">{strength.title}</h4>
                        <p className="subtitle">{strength.text}</p>
                      </div>
                    </div>

                    <div className="lg:px-5 px-4">
                      <h3 className="title-primary-sm mb-3">
                        {t("surveyResults.weakness.title")}
                      </h3>
                    </div>
                    <div className="lg:px-5 px-4">
                      <div className="align-top inline-block">
                        <span
                          className={`icon-strength-weakness bg-${weakness.title} mr-2`}
                        >
                          <img
                            className="icon-inner-img"
                            src={imageUrl + weakness.img}
                            alt={weakness.title}
                          />
                        </span>
                      </div>
                      <div className="align-middle inline-block w-2/3">
                        <h4 className="title-base">{weakness.title}</h4>
                        <p className="subtitle ">{weakness.text}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <SurveyTabs
            all_sum={all_sum}
            surveyResults={surveyResults}
            start_date={start_date}
            end_date={end_date}
            openTab={openTab}
            setOpenTab={setOpenTab}
            t={t}
            updateSurveyHandler={updateSurveyHandler}
            inviteEmailAddresses={inviteEmailAddresses}
            setInviteEmailAddresses={setInviteEmailAddresses}
            usersurveylink={`${frontendurl}/user-survey/${surveyResults.slug}`}
            selfsurveylink={`${frontendurl}/self-survey/${surveyResults.slug}`}
            copied={copied}
            setCopied={setCopied}
            sendInvite={sendInvite}
            surveyResultsId={surveyResults.id}
            surveyClosingDate={surveyResults.survey_closing_date}
            surveyInviteBased={surveyResults.invite_based}
            self_access_is_set={self_access_is_set}
            participantDetails={surveyResults?.detailed_participant_summary?.filter(
              (filtpart) =>
                filtpart?.email_invite?.email_address ? true : false
            )}
            userDetails={userDetails}
            sendEvent={sendEvent}
          />

          {show_user_registration_modal && all_sum >= 3 && (
            <ModalUserRegister
              updateUserDetails={updateUserDetails}
              t={t}
              setToggleActive={setToggleActive}
            />
          )}
          {surveyResults?.data?.categories?.map((item, ind) => {
            return (
              <SurveyResultsBox
                key={item.id}
                item={item}
                toggleActive={toggleActive}
                t={t}
                setToggleActive={setToggleActive}
              />
            );
          })}
          <div className="w-full mt-5">
            <h2 className="title-primary mb-8">
              {t("surveyResults.feedback.title")}
            </h2>
            {surveyResults?.data?.inputs[0]?.subelements[0]?.inputfields?.questions?.map(
              (subitem, ind) => {
                return (
                  <SurveyTextQuestionBox
                    key={subitem.question.id}
                    item={subitem}
                    expandActive={expandActive}
                    setExpandActive={setExpandActive}
                    showAllActive={showAllActive}
                    setShowAllActive={setShowAllActive}
                    t={t}
                  />
                );
              }
            )}
          </div>
          {all_sum >= 3 && !show_user_registration_modal && (
            <div className="mt-5 box-secondary px-10">
              <h2 className="title-white-2xl text-center mb-8">
                {t("surveyResults.summary.title")}
              </h2>
              <div className="flex flex-wrap lg:mb-11">
                <div className=" flex flex-wrap lg:pr-6 lg:w-1/2 w-full">
                  <div className="bg-white rounded-lg px-6 py-8 text-black">
                    <div className="lg:w-3/4 box-primary text-center p-7 bg-small-dark mx-auto mb-8">
                      <h3 className="text-md font-bold mb-9">
                        {t("surveyResults.summary.box_text_1")}
                        <br />
                        {t("surveyResults.summary.box_text_2")}
                      </h3>
                      <a
                        href="mailto:hi@veemind.com?subject=Enterprise%20version%20of%20Veemind&body=Hi%2C%0A%0AI'm%20interested%20in%20learning%20about%20VeeMind's%20enterprise%20offering.%0A%0ARegards%2C%0A"
                        className="button bg-secondary border-0 lg:w-[141px] text-white py-[10px] font-medium"
                      >
                        {t("surveyResults.summary.box_cta")}
                      </a>
                    </div>
                    <p className="font-bold">
                      {t("surveyResults.summary.text_bold")}
                    </p>
                    <p className="mb-4">{t("surveyResults.summary.text_1")}</p>
                    <p>{t("surveyResults.summary.text_2")}</p>
                    <ul className="list-disc pl-5">
                      <li>{t("surveyResults.summary.text_list_1")}</li>
                      <li>{t("surveyResults.summary.text_list_2")}</li>
                      <li>{t("surveyResults.summary.text_list_3")}</li>
                    </ul>
                  </div>
                </div>
                <div className="lg:pl-6 lg:w-1/2 w-full self-center">
                  {SurveySummaryResultsData &&
                    SurveySummaryResultsData.map((item, ind) => {
                      return <SurveySummaryResults key={ind} item={item} />;
                    })}
                  {/*<div className="text-center mt-10">
                    <a
                      href="/premium"
                      className="button bg-primary border-0 lg:w-[141px] text-white py-[10px] font-medium"
                    >
                      {t("surveyResults.summary.pdf_cta")}
                    </a>
                  </div>*/}
                </div>
              </div>
            </div>
          )}
        </section>
        {toggleActive !== null &&
          toggleActive !== undefined &&
          surveyResults?.data?.categories?.map((item, ind) => {
            return (
              toggleActive === item.title && (
                <ModalCategories
                  academylink={`${frontendurl}/academy`}
                  item={item}
                  t={t}
                  setToggleActive={setToggleActive}
                />
              )
            );
          })}
        <FooterInner align="center" />
      </div>
    )
  );
};

export default SurveyResults;
