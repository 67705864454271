import { useEffect } from "react";
import Header from "../modules/Header";
import Footer from "../modules/Footer";
import { useOutletContext, useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StartBox = ({ item, number, setSelectedTemplate, t }) => (
  <div className="start-box">
    <h3 className="font-medium mb-11">
      {t("home.card_small_title")} {String(number).padStart(2, 0)}
    </h3>
    <h2 className="title-2xl mb-7 min-h-[130px]">{item.title}</h2>
    <span className="block text-xs dark:text-gray_purple">
      {item.tags.data.map((tag) => {
        return `#${tag.attributes.name} `;
      })}
    </span>
    {/* temporarily set only the first card with "start survey" until further details */}
    {number === 1 && (
      <button
        id="event-0-select-template"
        onClick={() => setSelectedTemplate(item)}
        className="bg-secondary dark:rounded-none text-black border-secondary button w-[160px] block m-auto mt-[75px]"
      >
        {t("home.cta")}
      </button>
    )}
  </div>
);

const Home = (props) => {
  const { templates, selectedTemplate, setSelectedTemplate, surveyResults } =
    useOutletContext();
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    selectedTemplate && navigate("/survey/conf");
  }, [selectedTemplate, navigate]);

  return (
    <div className="dark">
      <Header />
      {/* forward user to results page if there is an instance of created survey.
      currently logic is set up to check for a single instance of a survey. */}
      {surveyResults?.id ? (
        <Navigate to={{ pathname: "/survey/results" }} />
      ) : (
        <section className="lg:h-screen flex flex-wrap justify-end overflow-hidden lg:px-0 px-[15px] pt-[70px] lg:pb-[70px] dark:bg-dark">
          {templates.data.map((item, ind) => {
            return (
              <StartBox
                key={item.id}
                item={{ id: item.id, ...item.attributes }}
                number={ind + 1}
                setSelectedTemplate={setSelectedTemplate}
                t={t}
              />
            );
          })}
        </section>
      )}
      <Footer />
    </div>
  );
};

export default Home;
