import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      // Survey header menu
      "menu.logout": "Logout",

      // Footer texts
      "footer.copyright": "2023 VeeMind",
      "footer.trust": "",
      "footer.support": "Support",
      "footer.impressum": "Impressum",

      //Survey Steps
      "surveySteps.completed_message": "you have already completed this survey",

      // Survey step 1
      "step1.owner": "This survey was created by",
      "step1.feature1": "Purpose",
      "step1.feature2": "Feedback",
      "step1.feature3": "Data",
      "step1.feature4": "Goal",
      "step1.explainer1":
        "You've been invited to this survey to help improve their leadership style",
      "step1.explainer2":
        "Please be honest and helpful in your answers and comments",
      "step1.explainer3": "Your data is treated confidentially and anonymously",
      "step1.explainer4":
        "Your input will help them reflect and improve, and contribute to your long-term collaboration",
      "step1.cta": "START SURVEY",
      "step1.timeestimate": "It should take you approx.",
      "step1.minutes": "minutes",

      // Survey step 2
      "step2.title": "Leadership survey",
      "step2.description": "Your input is very appreciated",
      "step2.cta": "NEXT STEP",

      //Survey Step 2 Values
      "almost never": "almost never",
      rarely: "rarely",
      sometimes: "sometimes",
      frequently: "frequently",
      often: "often",
      "almost always": "almost always",

      // Survey step 3
      "step3.title": "Almost done!",
      "step3.description":
        "Your almost at the end of this survey. In this last part we would like to ask you for open-ended feedback. Your responses will remain anonymous.",
      "step3.description-self": "Your almost done with the self-assessment",
      "step3.cta": "FINISH SURVEY",

      // Survey step 4
      "step4.title": "Thank you, we appreciate\n your input!",
      "step4.text":
        "If you want to share your experience with VeeMind Nano,\n we would be happy to hear about it at hi@veemind.com",
      "step4.teasertitle": "Are you a good leader?",
      "step4.teasertext":
        "Do you want to receive good and honest feedback from your employees? Start your own survey at for free with VeeMind",
      "step4.cta": "START SURVEY",

      // Content Card (Academy)
      "academy.category.title": "Element",
      "academy.subcategory.title": "Skill",
      "academy.reading_time": "Reading time",
      "academy.minutes": "min",
      "academy.intro.title": "Intro",
      "academy.content.title": "What can I do?",
      "academy.read-more": "READ MORE",

      //Home
      "home.card_small_title": "Survey",
      "home.cta": "Select survey",

      //Login
      "login.title": "Learn about your own leadership style",
      "login.text":
        "Create a survey to ask your team, or yourself,\n what kind of leader you are",
      "login.cta": "LOGIN",
      "login.cta_after_error": "Proceed to login",
      "login.terms_of_use.text": "By creating an account, I agree\nto the",
      "login.terms_of_use.cta": "terms of Services",

      //Survey Conf
      "surveyConf.category.title": "Element",
      "surveyConf.subcategory.title": "Skill",
      "surveyConf.subcategory.question_title": "Question",
      "surveyConf.page_title": "Start your survey",
      "surveyConf.page_text":
        "This is a preview of the questions that will be distributed",
      "surveyConf.box.title": "GUIDE",
      "surveyConf.box.text_1":
        "In the next step, you will be asked to distribute access information to your team members, you can do this by providing their e-mails or giving them a direct link\n\nThe sections below illustrate the system we will use to assess your leadership style and the question that we ask your team",
      "surveyConf.box.text_2":
        "To see the questions we will send to your team members, click to explore each element below. We will also ask them to answer three open questions where they can share their suggestions and feedback.",
      "surveyConf.stats.time": "This survey will approx. take:",
      "surveyConf.minutes": "min",
      "surveyConf.stats.categories": "Selected elements:",
      "surveyConf.stats.questions": "Total Questions:",
      "surveyConf.stats.cta": "CREATE SURVEY",

      //SurveyResults
      //Summary
      "surveyResults.summary.leadership_elements":
        "Leadership elements evaluated",
      "surveyResults.summary.participants": "Number of participants",
      "surveyResults.summary.skills": "Leadership skills evaluated",
      "surveyResults.summary.answers_given": "Answers given",
      "surveyResults.summary.total_exercises":
        "Total exercises available in full version",
      "surveyResults.summary.opinions_given":
        "Opinions written by participants",
      "surveyResults.summary.time_average": "Time spent on avg.",
      "surveyResults.summary.completion_rate": "Completion rate",
      "surveyResults.summary.title": "Summary",
      "surveyResults.summary.box_text_1": "VeeMind",
      "surveyResults.summary.box_text_2": "Enterprise",
      "surveyResults.summary.box_cta": "Ask us via e-mail",
      "surveyResults.summary.text_bold":
        "This survey is a teaser for the enterprise version of VeeMind",
      "surveyResults.summary.text_1": "Thank you for taking time to use it",
      "surveyResults.summary.text_2":
        "If you would like to use our solution in your company, our platform enables further data-driven decision-making, such as:",
      "surveyResults.summary.text_list_1":
        "Whole company leadership quality overview",
      "surveyResults.summary.text_list_2": "Historical statistics",
      "surveyResults.summary.text_list_3": "Easy survey management",
      "surveyResults.summary.pdf_cta": "Send as PDF",

      //Page Content
      "surveyResults.page_title": "Results overview",
      "surveyResults.page_text": "This page shows and explains your results",
      "surveyResults.no_results.title": "No results",
      "surveyResults.no_results.text":
        "Results will become available as soon as the surveys are completed",
      "surveyResults.strength.title": "STRENGTH",
      "surveyResults.weakness.title": "WEAKNESS",
      "surveyResults.feedback.title": "Open-ended feedback",
      "surveyResults.feedback.show_all": "show all",
      "surveyResults.category.title": "element",
      "surveyResults.subcategory.title": "Skill",
      "surveyResults.category.subcategories_included": "skills included",
      "surveyResults.subcategory.tooltip.self-assess": "Self assess",
      "surveyResults.subcategory.academy-cta": "Improve",
      "surveyResults.subcategory.question_title": "Question",

      //Tabs
      //Total Results
      "tab.results.survey_title.label": "Survey title",
      "tab.results.started_on.label": "Started on",
      "tab.results.end_date.label": "End date",
      "tab.results.end_date.text": "or when all responses are submitted",
      "tab.results.status.label": "Status",
      "tab.results.survey_closed": "This survey is now closed",
      //Tab Email
      "tab.email.title": "Using e-mail",
      "tab.email.invite-emails.label": "Invite via e-mail",
      "tab.email.invite-emails.placeholder":
        "Recipient e-mails (comma separated)",
      "tab.email.invite-emails.not_valid_message":
        "The following emails are not valid",
      "tab.email.invite-emails.already_sent_message":
        "The following have already been invited",
      "tab.email.invite-emails.send_to_your_email_message":
        "You cannot send an invite to yourself",
      "tab.email.tracking.label": "Response tracking",
      "tab.email.tracking.list_item_1": "automated reminders",
      "tab.email.tracking.list_item_2": "progress tracking",
      "tab.email.tracking.list_item_3": "survey closes with last respondent",
      "tab.email.end_date.label": "End date",
      "tab.email.end_date.text": "or when all responses are submitted",
      "tab.email.self-assess.cta": "self-assess",
      "tab.email.invite.cta": "invite",
      //Tab URL
      "tab.url.title": "Using a link",
      "tab.stats.title": "Status",
      "tab.url.invite-url.label": "Share URL",
      "tab.url.invite-url.text":
        "Use the link above to share with your team and make sure to click the button to start the survey.",
      "tab.url.invite-url.cta.share": "share",
      "tab.url.invite-url.cta.copied": "copied!",
      "tab.url.tracking.label": "Response tracking",
      "tab.url.tracking.text":
        "Inviting with e-mails helps you get the best results. E-mail invites enable:",
      "tab.url.tracking.list_item_1": "automated reminders",
      "tab.url.tracking.list_item_2": "progress tracking",
      "tab.url.tracking.list_item_3": "survey closes with last respondent",
      "tab.url.end_date.label": "End date",
      "tab.url.end_date.text": "Will close on midnight",
      "tab.url.self-assess.cta": "self-assess",
      //Register Modal
      "modal.register_user.title": "One more step to get your result",
      "modal.register_user.text": "Please complete the fields below to access your personalized leadership assessment",
      "modal.register_user.q1": "Your name?",
      "modal.register_user.first_name": "First name",
      "modal.register_user.last_name": "Last name",
      "modal.register_user.email": "Email",
      "modal.register_user.q2": "What best describes your role?",
      "modal.register_user.choose": "Choose one",
      "modal.register_user.q3": "What is your industry?",
      "modal.register_user.q4": "Specify your industry",
      "modal.register_user.q5": "Where are you located?",
      "modal.register_user.q6": "What is the size of the team you manage?",
      "modal.register_user.q7": "What is the size of your company?",
      "modal.register_user.q8":
        "Does your company have a leadership and/or company culture guide?",
      "modal.register_user.cta": "Save",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
