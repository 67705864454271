import React, { useEffect, useState, useCallback } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import {
  axiosGetUserData,
  axiosGetTemplateData,
  axiosGetSurveyRoute,
  axiosSendInviteRoute,
  axiosUpdateUserData,
  axiosSurveyRoute,
  axiosCompanyRoute,
  axiosCreatePartnerSurveyRoute,
  axiousDeleteSurveyRoute,
} from "./helpers/axios";

const ProtectedRoute = ({ notify }) => {
  const cookies = new Cookies();

  // set auth and userdetails to true, for development purposes
  const [isAuth, setIsAuth] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [surveyResults, setSurveyResults] = useState(null);
  const [inviteEmailAddresses, setInviteEmailAddresses] = useState("");

  const frontendurl = `${process.env.REACT_APP_FRONTEND_URL}`;
  const backendurl = `${process.env.REACT_APP_BACKEND_URL}`;

  const token = cookies.get("veemind-app-token");

  const { pathname } = useLocation();

  const getTemplates = useCallback(async () => {
    try {
      const result = await axiosGetTemplateData({
        params: {
          "populate[categories][populate][questions][populate][categories][populate][icon]":
            "*",
          "populate[categories][populate][icon]": "*",
          populate: "tags",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.data) {
        return result.data;
      }
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const getSurveyResults = useCallback(async () => {
    try {
      const result = await axiosGetSurveyRoute({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.data) {
        setSurveyResults(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const updateSurveyHandler = useCallback(
    async (surveyid, data) => {
      try {
        const result = await axiosSurveyRoute.put(
          `/${surveyid}`,
          {
            data,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data) {
          await getSurveyResults();
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token, getSurveyResults]
  );

  const sendInvite = useCallback(
    async (data) => {
      try {
        const result = await axiosSendInviteRoute({
          data: { data },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          setInviteEmailAddresses("");

          result.data.invites_sent > 0 &&
            notify(
              `Invite(s) sent to ${result.data.invites_sent} recipient(s)`,
              "success"
            );
          result.data.invites_failed > 0 &&
            notify(
              `Invite(s) to ${result.data.invites_failed} recipient(s) have already been sent`,
              "error"
            );

          if (result.data.invite_based === null) {
            updateSurveyHandler(surveyResults.id, {
              invite_based: true,
              set_closing_date: true,
            });
          } else {
            getSurveyResults();
          }
        }
      } catch (err) {
        notify(err.response.data.error.message, "error");
      }
    },
    [token, notify, getSurveyResults, surveyResults, updateSurveyHandler]
  );

  const getUserDetailsAndSurveyData = useCallback(async () => {
    try {
      const result = await axiosGetUserData({
        params: { populate: "*" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 200) {
        const surveyData = await getTemplates();

        await getSurveyResults();

        setIsAuth(true);
        setUserDetails(result.data);
        setTemplates(surveyData);
      }
    } catch (err) {
      setIsAuth(false);
      setUserDetails(null);
      console.log(err);
    }
  }, [token, getTemplates, getSurveyResults]);

  const updateUserDetails = useCallback(
    async (data) => {
      try {
        const result = await axiosUpdateUserData({
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
        });

        if (result) {
          setUserDetails(result.data);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const sendEvent = useCallback(
    async (data) => {
      console.log(data);
      try {
        let formdata = new URLSearchParams();
        formdata.append("actid", 28045100);
        formdata.append("key", "c1ebfdc51a663d5a4252fc99a65a9adf570a9989");
        formdata.append("event", data.event);
        formdata.append("eventdata", data.eventdata);
        formdata.append("visit", JSON.stringify({ email: data.email }));
        axios
          .post("https://trackcmp.net/event", formdata, {
            headers: {
              accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (response) {
            console.log(response.data);
          })
          .catch(function (error) {
            console.error(error);
          });
      } catch (err) {
        notify(err, "error");
        console.log(err);
      }
    },
    [notify]
  );

  const getAllCompanyData = useCallback(async () => {
    try {
      const result = await axiosCompanyRoute({
        method: "get",
        params: { "populate[0]": "surveys", "populate[1]": "logo" },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 200) {
        return result.data;
      }
    } catch (err) {
      console.log(err);
    }
  }, [token]);

  const createCompany = useCallback(
    async (data) => {
      try {
        const result = await axiosCompanyRoute({
          method: "post",
          data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          return result.data;
        }
      } catch (err) {
        throw err.response.data.error.details.errors;
      }
    },
    [token]
  );

  const modifyCompany = useCallback(
    async (data, id) => {
      try {
        const result = await axiosCompanyRoute(`/${id}`, {
          method: "put",
          data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          return result.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const deleteCompany = useCallback(
    async (id) => {
      try {
        const result = await axiosCompanyRoute(`/${id}`, {
          method: "delete",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          return result.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const deleteSurvey = useCallback(
    async (id) => {
      try {
        const result = await axiousDeleteSurveyRoute(`/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.status === 200) {
          return result.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  const createPartnerSurvey = useCallback(
    async (data) => {
      try {
        const results = await axiosCreatePartnerSurveyRoute({
          data: { data },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (results.status === 200) {
          return results.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    [token]
  );

  useEffect(() => {
    !isAuth && getUserDetailsAndSurveyData();
  }, [pathname, getUserDetailsAndSurveyData, isAuth]);

  if (isAuth === true && userDetails) {
    // User is authenticated, render the protected routes
    if (userDetails.role.name === "Admin") {
      // Admins are only allowed to enter the "/admin" subroute
      if (!pathname.startsWith("/admin")) {
        return <Navigate to={{ pathname: "/admin/" }} replace />;
      }
    } else if (pathname.startsWith("/admin")) {
      return <Navigate to={{ pathname: "/" }} replace />;
    }
  }

  return (
    <>
      {isAuth === true && userDetails && templates && (
        <>
          <Outlet
            context={{
              templates,
              selectedTemplate,
              setSelectedTemplate,
              userDetails,
              token,
              surveyResults,
              getSurveyResults,
              sendInvite,
              inviteEmailAddresses,
              setInviteEmailAddresses,
              frontendurl,
              backendurl,
              sendEvent,
              updateUserDetails,
              updateSurveyHandler,
              getAllCompanyData,
              createCompany,
              modifyCompany,
              createPartnerSurvey,
              deleteCompany,
              deleteSurvey,
              notify,
            }}
          />
        </>
      )}

      {isAuth === false && <Navigate to={{ pathname: "/login" }} />}
    </>
  );
};

export default ProtectedRoute;
