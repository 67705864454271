import axios from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json";

const axioslogin = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/auth/local/`,
  method: "post",
});

const axiosGetUserData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/users/me/`,
  method: "get",
});

const axiosUpdateUserData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/users/me/`,
  method: "put",
});

const axiosGetTemplateData = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/templates`,
  method: "get",
});

const axiosSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/surveys`,
  method: "post",
});

const axiousDeleteSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/surveys`,
  method: "delete",
});

const axiosGetSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/surveys`,
  method: "get",
});

const axiosGetPartnerSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/get-partner-survey`,
  method: "post",
});

const axiosCreatePartnerSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/create-partner-survey`,
  method: "post",
});

const axiosGetPartnerSurveyResults = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/get-partner-survey-results`,
  method: "post",
});

const axiosSelfSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/selfsurvey`,
  method: "post",
});

const axiosSubmitSurveyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/answers`,
  method: "post",
});

const axiosSendInviteRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/email-invites`,
  method: "post",
});

const axiosGetAcademyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/academy-contents`,
  method: "get",
});

const axiosCompanyRoute = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/companies`,
});

const axiosSendEventRoute = ({ event, eventdata, email }) => {
  return (dispatch) => {
    axios
      .post(
        "https://trackcmp.net/event",
        {
          actid: 28045100,
          key: "c1ebfdc51a663d5a4252fc99a65a9adf570a9989",
          event: event,
          eventdata: eventdata,
          visit: JSON.stringify({ email: email }),
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: "FETCH_TODO",
          payload: event,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export {
  axioslogin,
  axiosGetUserData,
  axiosGetTemplateData,
  axiosSurveyRoute,
  axiosGetSurveyRoute,
  axiosSubmitSurveyRoute,
  axiosSendInviteRoute,
  axiosSelfSurveyRoute,
  axiosGetAcademyRoute,
  axiosSendEventRoute,
  axiosUpdateUserData,
  axiosGetPartnerSurveyRoute,
  axiosGetPartnerSurveyResults,
  axiosCompanyRoute,
  axiosCreatePartnerSurveyRoute,
  axiousDeleteSurveyRoute,
};
