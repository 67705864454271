import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import HeaderPartner from "../modules/HeaderPartner";
import FooterInner from "../modules/FooterInner";
import {
  SurveyResultsBox,
  ModalCategories,
  ModalPartnerUserRegister,
} from "../modules/partials/survey-results-partials";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { axiosGetPartnerSurveyResults } from "../helpers/axios";
import { marked } from "marked";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const backendUrl = process.env.REACT_APP_BACKEND_URL;

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const SurveyResults = ({ notify }) => {
  let { anon_request } = useParams();

  const [toggleActive, setToggleActive] = useState();
  const [surveyResults, setSurveyResults] = useState(null);
  const [userDetails, setUserDetails] = useState({
    user_email: "",
    first_name: "",
    last_name: "",
  });

  const [loadingData, setLoadingData] = useState(false);

  const [showUserRegistrationModal, setShowUserRegistrationModal] =
    useState(true);

  const getSurveyResults = useCallback(
    async (anon_request_uuid) => {
      try {
        const result = await axiosGetPartnerSurveyResults(
          `/${anon_request_uuid}`
        );

        if (result.data) {
          setSurveyResults(result.data);
          if (result?.data?.userDetailsComplete) {
            setShowUserRegistrationModal(false);
          } else {
            if (result.data.user_email) {
              setUserDetails((prev) => {
                return {
                  ...prev,
                  user_email: result.data.user_email,
                };
              });
            }
          }
        }
      } catch (err) {
        notify(err?.response?.data?.error?.message, "error");
      }
    },
    [notify]
  );

  const updateUserDetails = useCallback(async () => {
    setLoadingData(true);
    try {
      const result = await axiosGetPartnerSurveyResults(`/${anon_request}`, {
        data: {
          user_email: userDetails.user_email,
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          update_user: true,
        },
      });

      if (result.data) {
        getSurveyResults(anon_request);
      }
    } catch (err) {
      setLoadingData(false);
      console.log(err);
    }
  }, [anon_request, userDetails, getSurveyResults]);

  // const frontendurl = process.env.REACT_APP_FRONTEND_URL;

  const { t } = useTranslation();
  const radarData = {
    labels: [
      "Growth",
      "Orientation",
      "Collaboration",
      "Balance",
      "Learning",
      "Ownership",
    ],
    short_labels: ["gr", "or", "co", "ba", "ln", "op"],
    datasets: [
      {
        data: [],
        backgroundColor: [
          "rgba(216, 240, 232, 0.7)",
          "rgba(222, 238, 249, 0.7)",
          "rgba(252, 244, 228, 0.9)",
          "rgba(229, 227, 241, 0.8)",
          "rgba(237, 228, 246, 0.8)",
          "rgba(251, 230, 237, 0.8)",
        ],
        borderWidth: 0.2,
        borderColor: [
          "#32B78B",
          "#479CDA",
          "#CE992F",
          "#3A2699",
          "#7A4CC4",
          "#E2376B",
        ],
        circular: "inner",
        borderJoinStyle: "round",
      },
    ],
  };
  var strength = { title: "", text: "", value: null, img: "" };
  var weakness = { title: "", text: "", value: null, img: "" };

  if (surveyResults?.data?.categories) {
    radarData.datasets[0].data = surveyResults?.data?.categories?.map(
      (item, ind) => {
        if (radarData.labels[ind] !== item.title)
          //if not category isset return minimum 1
          return 1;
        else {
          var rating = 0;
          var responses = 0;
          item.subelements.map((subitem, skill_ind) => {
            subitem.subcategory.questions.map((question, skill_ind) => {
              rating += question.answer.rating ?? 0;
              responses += question.answer.possible_score_range ?? 0;
              // if (question.answer.self_rating > 0) self_access_is_set = true;
              return [rating, responses];
            });
            return [rating, responses];
          });
          item.sum_rating = rating % 1 === 0 ? rating : rating.toFixed(1);
          item.sum_responses = responses;
          var percentage = (rating * 100) / responses;
          if (
            strength.value === null ||
            parseFloat(strength.value) < percentage
          )
            strength = {
              title: item.title,
              text: item.text,
              value: percentage,
              img: item.icon.url,
            };
          if (weakness.value === null || weakness.value > percentage)
            weakness = {
              title: item.title,
              text: item.text,
              value: percentage,
              img: item.icon.url,
            };
          var $return = (percentage * 5) / 100; //convert to top 5
          return $return % 1 === 0 ? $return : $return.toFixed(1);
        }
      }
    );
  }

  useEffect(() => {
    if (anon_request) {
      getSurveyResults(anon_request);
    }
  }, [getSurveyResults, anon_request]);

  const { survey_text_supplement, name, logo, tag_line } =
    surveyResults?.company_details || {};

  const processHTMLWithTargetBlank = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    if (links.length === 0) return html;

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.documentElement.innerHTML;
  };

  const htmlContent = marked(survey_text_supplement || "");
  const modifiedHtmlContent = processHTMLWithTargetBlank(htmlContent);
  const customtext = (
    <div dangerouslySetInnerHTML={{ __html: modifiedHtmlContent }} />
  );

  const company_logo = backendUrl && logo && `${backendUrl}${logo?.url}`;

  return (
    surveyResults?.data && (
      <div>
        <HeaderPartner company_logo={company_logo} company_name={name} />

        <section
          className={`container lg:px-0 flex flex-wrap pt-[140px] pb-[60px] mx-auto lg:w-[955px]${
            showUserRegistrationModal ? " no-results" : ""
          }`}
        >
          <div className="lg:w-[calc(50%-17px)] w-full mb-[34px] self-end">
            <h2 className="title-primary mb-2">
              {t("surveyResults.page_title")}
            </h2>
            <p className="subtitle  mb-5">{t("surveyResults.page_text")}</p>
            <div className="bg-[#E9E9E9] flex flex-wrap rounded-[15px] p-6">
              <>
                <div className="sm:w-1/2 w-full text-center bg-white rounded-[15px] pt-3 pb-3 px-1 sm:mb-0 mb-4">
                  <h3 className="title-primary-sm mb-1 uppercase">
                    Results scorecard
                  </h3>
                  <PolarArea
                    className="mx-auto max-w-full p-2"
                    data={radarData}
                    options={{
                      animation: { duration: 1400 },
                      plugins: {
                        ChartDataLabels,
                        datalabels: {
                          formatter: function (value, context) {
                            return radarData.short_labels[
                              context.dataIndex
                            ].toUpperCase();
                          },
                          anchor: "end",
                          align: "center",
                          font: {
                            family: "Poppins, sans-serif",
                            weight: "bold",
                          },
                        },
                        legend: {
                          display: false,
                        },
                      },
                      clip: { left: 0, top: 0, right: 0, bottom: 0 },
                      scales: {
                        r: {
                          angleLines: {
                            display: true,
                          },
                          ticks: {
                            maxTicksLimit: 1,
                            display: false,
                          },
                          pointLabels: {
                            display: false,
                          },
                        },
                      },
                    }}
                  />
                </div>

                <div className="sm:w-1/2 w-full pt-3">
                  <div className="lg:px-5 px-4">
                    <h3 className="title-primary-sm mb-3">
                      {t("surveyResults.strength.title")}
                    </h3>
                  </div>
                  <div className="lg:px-5 px-4 mb-7">
                    <div className="align-top inline-block">
                      <span
                        className={`icon-strength-weakness bg-${strength.title} mr-2 `}
                      >
                        <img
                          className="icon-inner-img"
                          src={imageUrl + strength.img}
                          alt={strength.title}
                        />
                      </span>
                    </div>
                    <div className="align-middle inline-block w-2/3">
                      <h4 className="title-base">{strength.title}</h4>
                      <p className="subtitle">{strength.text}</p>
                    </div>
                  </div>

                  <div className="lg:px-5 px-4">
                    <h3 className="title-primary-sm mb-3">
                      {t("surveyResults.weakness.title")}
                    </h3>
                  </div>
                  <div className="lg:px-5 px-4">
                    <div className="align-top inline-block">
                      <span
                        className={`icon-strength-weakness bg-${weakness.title} mr-2`}
                      >
                        <img
                          className="icon-inner-img"
                          src={imageUrl + weakness.img}
                          alt={weakness.title}
                        />
                      </span>
                    </div>
                    <div className="align-middle inline-block w-2/3">
                      <h4 className="title-base">{weakness.title}</h4>
                      <p className="subtitle ">{weakness.text}</p>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
          {showUserRegistrationModal && (
            <ModalPartnerUserRegister
              loadingData={loadingData}
              updateUserDetails={updateUserDetails}
              t={t}
              setToggleActive={setToggleActive}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
            />
          )}
          {surveyResults?.data?.categories?.map((item, ind) => {
            return (
              <SurveyResultsBox
                key={item.id}
                item={item}
                toggleActive={toggleActive}
                t={t}
                setToggleActive={setToggleActive}
              />
            );
          })}

          <div className="box-secondary px-10">
            <div className="flex items-center justify-center">
              <div className=" flex flex-wrap lg:pr-6 lg:w-1/2 w-full">
                <div className="bg-white rounded-lg px-6 py-8 text-black">
                  <div className="lg:w-3/4 p-7 mx-auto mb-8">
                    <div className="flex col mb-4 items-center justify-center space-x-4">
                      <img alt="logo" src={company_logo} />
                      <div className="text-lg">{tag_line}</div>
                    </div>
                    <div
                      // href="mailto:hi@veemind.com?subject=Enterprise%20version%20of%20Veemind&body=Hi%2C%0A%0AI'm%20interested%20in%20learning%20about%20VeeMind's%20enterprise%20offering.%0A%0ARegards%2C%0A"
                      className="button bg-secondary border-0 lg:w-full text-white py-[10px] font-medium"
                    >
                      <div>Preview your results for free with</div>
                      <div>{name}</div>
                    </div>
                  </div>

                  <div className="text-center">{customtext}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {toggleActive !== null &&
          toggleActive !== undefined &&
          surveyResults?.data?.categories?.map((item, ind) => {
            return (
              toggleActive === item.title && (
                <ModalCategories
                  academylink={null}
                  item={item}
                  t={t}
                  setToggleActive={setToggleActive}
                />
              )
            );
          })}
        <FooterInner align="center" />
      </div>
    )
  );
};

export default SurveyResults;
