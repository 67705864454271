import logo from "../images/logo.svg";

import Cookies from "universal-cookie";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const cookies = new Cookies();

const logOut = () => {
  cookies.remove("veemind-app-token", { path: "/" });
  window.location.href = `https://${process.env.REACT_APP_AUTH0_SUBDOMAIN}.auth0.com/v2/logout?returnTo=${process.env.REACT_APP_FRONTEND_URL}&client_id=${process.env.REACT_APP_AUTH0_CLIENTID}`;
};

const Header = ({ username, mode }) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <header className="fixed top-0 left-0 w-screen bg-white z-10">
      <div className="container mx-auto lg:py-6 py-4 flex flex-row font-base">
        <div className="basis-1/3 self-center">
          <a href="/">
            <img src={logo} width="134" height="23" alt="VeeMind" />
          </a>
        </div>

        <div className="basis-2/3 text-right lg:text-base text-sm self-center">
          <a
            href="http://veemind.com/support"
            target="_blank"
            rel="noreferrer"
            className="text-primary lg:ml-7 ml-3"
          >
            {t("footer.support")}
          </a>
          {mode !== "usersurvey" && location.pathname !== "/login" && (
            <div className="inline-block">
              <span className="subtitle lg:ml7 ml-3">{username}</span>
              <button
                onClick={logOut}
                className="button border-primary text-primary lg:w-[144px] lg:ml-3 ml-3"
              >
                {t("menu.logout")}
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
