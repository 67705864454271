import { MuiChipsInput } from "mui-chips-input";
import { Countries } from "./countries";
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const SurveyTabs = ({
  openTab,
  setOpenTab,
  t,
  inviteEmailAddresses,
  setInviteEmailAddresses,
  usersurveylink,
  selfsurveylink,
  copied,
  setCopied,
  sendInvite,
  surveyInviteBased,
  surveyResultsId,
  surveyClosingDate,
  participantDetails,
  userDetails,
  self_access_is_set,
  sendEvent,
  surveyResults,
  start_date,
  end_date,
  all_sum,
  updateSurveyHandler,
}) => {
  return (
    <div className="box-primary survey-box p-6 h-auto survey-tabs">
      {all_sum >= 3 && (
        <button
          className={`survey-tab${openTab === "stats" ? " active" : ""}`}
          onClick={(e) => {
            setOpenTab("stats");
          }}
        >
          {t("tab.stats.title")}
        </button>
      )}
      {((surveyInviteBased === true &&
        all_sum >= 3 &&
        surveyResults.survey_closed === null) ||
        all_sum < 3) && (
        <button
          disabled={surveyInviteBased === false}
          className={`disabled:opacity-30 survey-tab${
            openTab === "invite" ? " active" : ""
          }`}
          onClick={(e) => {
            setOpenTab("invite");
          }}
        >
          {t("tab.email.title")}
        </button>
      )}
      {((!surveyInviteBased &&
        all_sum >= 3 &&
        surveyResults.survey_closed === null) ||
        all_sum < 3) && (
        <button
          disabled={surveyInviteBased === true}
          className={`disabled:opacity-30 survey-tab${
            openTab === "link" ? " active" : ""
          }`}
          onClick={(e) => {
            setOpenTab("link");
          }}
        >
          {t("tab.url.title")}
        </button>
      )}
      {openTab === "link" && (
        <>
          <div className="relative mb-2">
            <label
              htmlFor="inviteUrl"
              className="text-sm w-[110px] mr-3 inline-block align-top"
            >
              {t("tab.url.invite-url.label")}
            </label>
            <div className="inline-block align-top w-[calc(100%-123px)]">
              <input
                type="text"
                name="inviteUrl"
                id="inviteUrl"
                className="text w-full pr-[105px] mb-2"
                value={usersurveylink}
                readOnly
              />
              <button
                id="event-2-invited-by-link"
                onClick={() => {
                  if (surveyInviteBased === null) {
                    updateSurveyHandler(surveyResults.id, {
                      invite_based: false,
                      set_closing_date: true,
                    });
                  }
                  setCopied(true);
                  navigator.clipboard.writeText(usersurveylink);
                  setTimeout(() => {
                    setCopied(false);
                  }, [5000]);
                }}
                className={`button rounded-[7px] ${
                  copied ? "bg-yellow-400" : "bg-secondary"
                } text-primary border-none py-[5px] w-[93px] absolute right-[6px] top-[5px]`}
              >
                {t(
                  copied
                    ? "tab.url.invite-url.cta.copied"
                    : "tab.url.invite-url.cta.share"
                )}
              </button>
              {t("tab.url.invite-url.text")}
            </div>
          </div>
          <div className="relative mb-2">
            <label
              htmlFor="responseTracking"
              className="text-sm w-[110px] mr-3 inline-block align-middle"
            >
              {t("tab.url.tracking.label")}
            </label>
            <div className="inline-block align-middle w-[calc(100%-123px)] bg-[rgba(226,55,107,.5)] text-white rounded-[10px] p-2 text-[10px]">
              {t("tab.url.tracking.text")}
              <ul className="pl-4 list-disc">
                <li>{t("tab.url.tracking.list_item_1")}</li>
                <li>{t("tab.url.tracking.list_item_2")}</li>
                <li>{t("tab.url.tracking.list_item_3")}</li>
              </ul>
            </div>
          </div>
          <div className="relative mb-5">
            <span
              htmlFor="endDate"
              className="font-medium text-[12px] w-[110px] mr-3 inline-block align-middle"
            >
              {t("tab.url.end_date.label")}
            </span>
            {surveyClosingDate ? (
              <span className="inline-block align-middle w-[calc(100%-123px)]">
                {t("tab.url.end_date.text")}{" "}
                <strong>
                  {new Date(surveyClosingDate).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </strong>
              </span>
            ) : (
              "TBD"
            )}
          </div>
          <div className="relative leading-[16px] py-1">
            <a
              className={`font-medium hidden text-[12px] w-[110px] mr-3 inline-block align-middle button rounded-[7px] text-white border-white py-[5px]${
                self_access_is_set && " pointer-events-none opacity-50"
              }`}
              href={`${selfsurveylink}`}
              rel="noreferrer"
              onClick={(e) => {
                sendEvent({
                  event: "launch-selfassessment",
                  eventdata: Date.now(),
                  email: userDetails.email,
                });
              }}
            >
              {t("tab.email.self-assess.cta")}
            </a>
            {/* <button className="button bg-secondary text-primary border-none rounded-[7px] w-[110px] py-[6px] absolute right-[6px] top-1">
              {t("invite")}
            </button> */}
          </div>
        </>
      )}
      {openTab === "stats" && (
        <>
          {surveyResults.survey_closed && (
            <div className="relative mb-7">
              <span className="text-lg font-bold">
                {t("tab.results.survey_closed")}
              </span>
            </div>
          )}
          <div className="relative mb-7">
            <span className="text-sm w-[110px] mr-3 inline-block align-top">
              {t("tab.results.survey_title.label")}
            </span>
            <div className="inline-block align-top w-[calc(100%-123px)] font-bold">
              {surveyResults?.title}
            </div>
          </div>
          <div className="relative mb-7">
            <span className="text-sm w-[110px] mr-3 inline-block align-top">
              {t("tab.results.started_on.label")}
            </span>
            <div className="inline-block align-top w-[calc(100%-123px)] font-bold">
              {start_date.toLocaleDateString()}
            </div>
          </div>
          <div className="relative mb-7">
            <span className="text-sm w-[110px] mr-3 inline-block align-top">
              {t("tab.results.end_date.label")}
            </span>
            {end_date ? (
              <div className="inline-block align-top w-[calc(100%-123px)] font-bold">
                {end_date.toLocaleDateString()}
                {surveyInviteBased && (
                  <span className="block font-normal text-sm">
                    {t("tab.results.end_date.text")}
                  </span>
                )}
              </div>
            ) : (
              "TBD"
            )}
          </div>
          {surveyResults?.data?.summary?.completion_rate_count !== "N/A" && (
            <div className="relative">
              <span className="text-sm w-[110px] mr-3 inline-block align-bottom">
                {t("tab.results.status.label")}
              </span>
              <div className="inline-block align-top w-[calc(100%-123px)]">
                <span className="text-sm mb-2 block">
                  {surveyResults?.data?.summary?.participant_complete_count}
                  <span className="text-secondary"> / </span>
                  {surveyResults?.data?.summary?.participant_count}
                </span>
                <div className="progress-bar bg-[#281A6A]">
                  <span
                    style={{
                      width:
                        surveyResults?.data?.summary?.completion_rate_count,
                    }}
                    className="!bg-secondary"
                  ></span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {openTab === "invite" && (
        <>
          <div className="relative mb-5">
            <label
              htmlFor="invitePerEmail"
              className="text-sm w-[110px] mr-3 inline-block align-top pt-[10px]"
            >
              {t("tab.email.invite-emails.label")}
            </label>

            {/* <textarea
                name="invitePerEmail"
                id="invitePerEmail"
                type="text"
                value={inviteEmailAddresses}
                onChange={(e) => setInviteEmailAddresses(e.target.value)}
                className="text inline-top align-middle w-[calc(100%-123px)] pr-[105px] h-[94px]"
                placeholder={`${t("tab.email.invite-emails.placeholder")}`}
              /> */}
            <MuiChipsInput
              multiline
              sx={{
                "& fieldset": { border: "none" },
              }}
              validate={(chipValue) => {
                const testforerrorsarray = chipValue.split(/[\s,]+/);

                let testforbadformatting = testforerrorsarray.filter(
                  (newchip) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,30}$/i.test(newchip)
                      ? false
                      : true
                );

                let testforduplication = testforerrorsarray.filter((newchip) =>
                  participantDetails.some(
                    (participant) =>
                      participant.email_invite.email_address === newchip
                  )
                );

                let testforself = testforerrorsarray.filter(
                  (newchip) => userDetails.email === newchip
                );

                return {
                  isError:
                    testforbadformatting.length > 0 ||
                    testforduplication.length > 0 ||
                    testforself.length === 1,
                  textError: `${
                    testforbadformatting.length > 0
                      ? `${t(
                          "tab.email.invite-emails.not_valid_message"
                        )}: ${testforbadformatting.join(", ")}\n`
                      : ""
                  } ${
                    testforduplication.length > 0
                      ? `${t(
                          "tab.email.invite-emails.already_sent_message"
                        )}: ${testforduplication.join(", ")}\n`
                      : ""
                  } ${
                    testforself.length === 1
                      ? `${t(
                          "tab.email.invite-emails.send_to_your_email_message"
                        )}: ${testforself[0]}`
                      : ""
                  }`,
                };
              }}
              hideClearAll
              size={"small"}
              name="invitePerEmail"
              id="invitePerEmail"
              className="text inline-top align-middle w-[calc(100%-123px)] pr-[105px] h-[94px] overflow-auto"
              value={inviteEmailAddresses}
              onChange={(chips) => {
                let parsedchips = [];
                chips.forEach((chip) => {
                  parsedchips = [...parsedchips, ...chip.split(/[\s,]+/)];
                });
                setInviteEmailAddresses(parsedchips);
              }}
            />
          </div>

          <div className="relative mb-5">
            <label
              htmlFor="responseTracking"
              className="text-sm w-[110px] mr-3 inline-block align-middle"
            >
              {t("tab.email.tracking.label")}
            </label>
            <div className="inline-block align-middle w-[calc(100%-123px)]">
              <ul className="pl-4 list-disc">
                <li>{t("tab.email.tracking.list_item_1")}</li>
                <li>{t("tab.email.tracking.list_item_2")}</li>
                <li>{t("tab.email.tracking.list_item_3")}</li>
              </ul>
            </div>
          </div>
          <div className="relative mb-5">
            <span
              htmlFor="endDate"
              className="font-medium text-[12px] w-[110px] mr-3 inline-block align-middle"
            >
              {t("tab.email.end_date.label")}
            </span>
            {surveyClosingDate ? (
              <span className="inline-block align-middle w-[calc(100%-123px)]">
                {t("Will close on midnight")}{" "}
                <strong>
                  {new Date(surveyClosingDate).toLocaleDateString("en-GB", {
                    timeZone: "UTC",
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </strong>{" "}
                {t("tab.email.end_date.text")}
              </span>
            ) : (
              "TBD"
            )}
          </div>
          <div className="relative leading-[16px] py-1">
            <a
              className={`font-medium text-[12px] w-[110px] mr-3 inline-block align-middle button rounded-[7px] text-white border-white py-[5px]${
                self_access_is_set && " pointer-events-none opacity-50"
              }`}
              href={`${selfsurveylink}`}
              rel="noreferrer"
              onClick={(e) => {
                sendEvent({
                  event: "launch-selfassessment",
                  eventdata: Date.now(),
                  email: userDetails.email,
                });
              }}
            >
              {t("tab.email.self-assess.cta")}
            </a>
            <button
              id="event-2-invited-by-email"
              disabled={inviteEmailAddresses?.length === 0}
              onClick={(e) => {
                e.preventDefault();
                sendInvite({
                  email_address: inviteEmailAddresses,
                  survey_id: surveyResultsId,
                });
              }}
              className="button disabled:opacity-50 bg-secondary text-primary border-none rounded-[7px] w-[110px] py-[6px] absolute right-[6px] top-1"
            >
              {t("tab.email.invite.cta")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const SurveyResultsBox = ({ item, toggleActive, t, setToggleActive }) => (
  <div
    className={`survey-box self-end lg:h-[249px] element-${item.title}${
      toggleActive === item.title ? " active" : ""
    }`}
    id={item.title}
  >
    <span className="survey-image">
      <img src={imageUrl + item.icon?.url} alt={item.title} />
    </span>
    <div className="inline-block align-middle lg:max-w-[calc(100%-125px)] max-w-[calc(100%-85px)]">
      <h4 className="subtitle">{t("surveyResults.category.title")}</h4>
      <h3 className="title-2xl">{item.title}</h3>
      <p className="title-sm mb-3">{item.text}</p>
      <p className="subtitle">
        {t("surveyResults.category.subcategories_included")}{" "}
        <span className="w-[21px] h-[21px] leading-[21px] inline-block bg-white rounded-full text-center text-[#232121] ml-10">
          {item.subelements.length}
        </span>
      </p>
    </div>
    <div className="w-[85%] mt-8">
      <span className="text-sm mb-2 block">
        {item.sum_rating}
        <span className={`text-${item.title}`}> / </span>
        {item.sum_responses}
      </span>
      <div className="progress-bar bg-white">
        <span
          style={{
            width: `calc(${item.sum_rating}*100%/${item.sum_responses})`,
          }}
          className={`bg-${item.title}`}
        ></span>
      </div>
    </div>
    {item.subelements && (
      <button
        className="expand-button"
        onClick={(e) => {
          setToggleActive((prior) => {
            return prior === item.title ? null : item.title;
          });
        }}
      ></button>
    )}
  </div>
);
const SurveyTextQuestionBox = ({
  item,
  expandActive,
  setExpandActive,
  showAllActive,
  setShowAllActive,
  t,
}) => (
  <div
    className={`text-question-box${
      expandActive === item.question.id ? " active" : ""
    }`}
    id={item.question.id}
  >
    <h3>
      {item.question.text}
      <button
        className="expand-button bg-primary lg:right-8 right-5 top-6"
        onClick={(e) => {
          setExpandActive((prior) => {
            return prior === item.question.id ? null : item.question.id;
          });
          setShowAllActive(null);
        }}
      ></button>
    </h3>
    <div className="text-question-body flex-wrap">
      {item.answer?.map((subitem, subind) => {
        return (
          (showAllActive === true || subind <= 5) && (
            <div
              key={subitem.id}
              className="bg-[#F8F8F8] px-8 py-6 rounded-[14px] mb-3 lg:w-[calc(50%-6px)] lg:even:ml-3 italic text-[#696484]"
            >
              {subitem.response}
            </div>
          )
        );
      })}
      {item.answer.length > 6 && showAllActive == null && (
        <div className="w-full text-center my-4">
          <button
            className="button font-semibold text-primary"
            onClick={(e) => {
              setShowAllActive(true);
            }}
          >
            {t("surveyResults.feedback.show_all")} ({item.answer.length})
          </button>
        </div>
      )}
    </div>
  </div>
);
const SurveySummaryResults = ({ item }) => (
  <div className="summary-item">
    <span className="inline-block w-3/5 align-middle">{item.title}</span>
    <span className="inline-block w-2/5 align-middle title-lg text-end">
      {item.result}
    </span>
  </div>
);
const ModalCategories = ({ item, t, setToggleActive, academylink }) => (
  <div className={`modal modal-${item.title}`}>
    <div
      className="overlay"
      onClick={(e) => {
        setToggleActive((prior) => {
          return null;
        });
      }}
    ></div>
    <div className="modal-body">
      <button
        className="close-button"
        onClick={(e) => {
          setToggleActive((prior) => {
            return null;
          });
        }}
      >
        X
      </button>
      <div className="modal-overflow">
        <div className="modal-inner">
          <div
            className={`survey-box element-${item.title} active `}
            id={item.title}
          >
            <span className="survey-image">
              <img src={imageUrl + item.icon?.url} alt={item.title} />
            </span>
            <div className="inline-block align-middle lg:max-w-[calc(100%-125px)] max-w-[calc(100%-85px)]">
              <h4 className="subtitle">{t("surveyResults.category.title")}</h4>
              <h3 className="title-2xl">{item.title}</h3>
              <p className="title-sm mb-3">{item.text}</p>
              <p className="subtitle">
                {t("surveyResults.category.subcategories_included")}{" "}
                <span className="w-[21px] h-[21px] leading-[21px] inline-block bg-white rounded-full text-center text-[#232121] ml-10">
                  {item.subelements.length}
                </span>
              </p>
            </div>
            <div className="w-[85%] mt-10">
              <span className="text-sm mb-2 block float-right">
                {item.sum_rating}
                <span className={`text-${item.title}`}> / </span>
                {item.sum_responses}
              </span>
              <p className="title-sm mb-3">{item.text}</p>
              <div className="progress-bar bg-white">
                <span
                  style={{
                    width: `calc(${item.sum_rating}*100%/${item.sum_responses})`,
                  }}
                  className={`bg-${item.title}`}
                ></span>
              </div>
            </div>
          </div>
          {item.subelements &&
            item.subelements?.map((subitem, skill_ind) => {
              return (
                <SurveyResultsSubCategoryBox
                  academylink={academylink}
                  // key={subitem.id}
                  item={subitem}
                  parent_title={item.title}
                  skill_ind={skill_ind}
                  t={t}
                />
              );
            })}
        </div>
      </div>
    </div>
  </div>
);
const ModalUserRegister = ({ t, setToggleActive, updateUserDetails }) => (
  <>
    <div className="relative w-full z-1">
      <div className="registration-modal">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateUserDetails({
              first_name: e.target.first_name.value,
              last_name: e.target.last_name.value,
              company_role: e.target.company_role.value,
              industry: e.target.industry.value,
              country: e.target.country.value,
              team_size: e.target.team_size.value,
              company_size: e.target.company_size.value,
              company_guide: e.target.company_guide.value,
              tag: "app-journey-finished",
            });
          }}
        >
          <h3 className="title-primary mb-2">
            {t("modal.register_user.title")}
          </h3>
          <p className="subtitle mb-8">{t("modal.register_user.text")}</p>
          <label className="block w-full mb-1" htmlFor="first_name">
            {t("modal.register_user.q1")}
          </label>
          <input
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-[48%]"
            type="text"
            name="first_name"
            id="first_name"
            placeholder={t("modal.register_user.first_name")}
            required
          />
          <input
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-[48%] lg:ml-3 ml-2"
            type="text"
            name="last_name"
            id="last_name"
            placeholder={t("modal.register_user.last_name")}
            required
          />

          <label className="block w-full mb-1" htmlFor="company_role">
            {t("modal.register_user.q2")}
          </label>
          <select
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-full"
            name="company_role"
            id="company_role"
            required
          >
            <option selected value="" disabled>
              {t("modal.register_user.choose")}
            </option>
            <option>CEO/Founder</option>
            <option>President/C-level exec</option>
            <option>VP/Director</option>
            <option>Manager/Supervisor</option>
            <option>Individual contributor/Specialist</option>
          </select>

          <label className="block w-full mb-1" htmlFor="industry">
            {t("modal.register_user.q3")}
          </label>
          <select
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-full"
            name="industry"
            id="industry"
            required
          >
            <option disabled selected value="">
              {t("modal.register_user.choose")}
            </option>
            <option>Consulting</option>
            <option>Consumer Goods & Services</option>
            <option>Education</option>
            <option>
              Engineering (civil, mechanical, industrial or automotive)
            </option>
            <option>Finance, Banking, Accounting</option>
            <option>Government, Public sector</option>
            <option>Healthcare</option>
            <option>Information Technology and Services</option>
            <option>Manufacturing</option>
            <option>Marketing/Advertising & Media</option>
            <option>Retail, E-Commerce</option>
            <option>Training & Coaching</option>
            <option>Other</option>
          </select>

          {/* <div className="hidden">
            <label className="block w-full mb-1" htmlFor="industry_text">
              {t("modal.register_user.q4")}
            </label>
            <textarea
              className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-full"
              name="industry_text"
              id="industry_text"
              required
            ></textarea>
          </div> */}

          <label className="block w-full mb-1" htmlFor="country">
            {t("modal.register_user.q5")}
          </label>
          <Countries t={t} />

          <label className="block w-full mb-1" htmlFor="team_size">
            {t("modal.register_user.q6")}
          </label>
          <select
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-full"
            name="team_size"
            id="team_size"
            required
          >
            <option disabled selected value="">
              {t("modal.register_user.choose")}
            </option>
            <option>Small (1-5)</option>
            <option>Medium (6-15)</option>
            <option>Large (16-30)</option>
            <option>Very Large (31+)</option>
          </select>

          <label className="block w-full mb-1" htmlFor="company_size">
            {t("modal.register_user.q7")}
          </label>
          <select
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-full"
            name="company_size"
            id="company_size"
            required
          >
            <option disabled selected value="">
              {t("modal.register_user.choose")}
            </option>
            <option>Small (1-50)</option>
            <option>Medium (51-200)</option>
            <option>Large (201-1000)</option>
            <option>Very Large (1001+)</option>
          </select>

          <label className="block w-full mb-1" htmlFor="company_guide">
            {t("modal.register_user.q8")}
          </label>
          <select
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-full"
            name="company_guide"
            id="company_guide"
            required
          >
            <option disabled selected value="">
              {t("modal.register_user.choose")}
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
          <button
            type="submit"
            className="disabled:opacity-20 button bg-secondary border-0 w-full text-black py-4 font-medium"
          >
            {t("modal.register_user.cta")}
          </button>
        </form>
      </div>
    </div>
    <div className=""></div>
  </>
);

const ModalPartnerUserRegister = ({
  t,
  updateUserDetails,
  setUserDetails,
  userDetails,
  loadingData,
}) => (
  <>
    <div className="relative w-full z-1">
      <div className="registration-modal">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateUserDetails();
          }}
        >
          <h3 className="title-primary mb-2">
            {t("modal.register_user.title")}
          </h3>
          <p className="subtitle mb-8">{t("modal.register_user.text")}</p>
          <input
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-[48%]"
            type="text"
            name="first_name"
            id="first_name"
            placeholder={t("modal.register_user.first_name")}
            required
            value={userDetails.first_name}
            onChange={(e) => {
              setUserDetails((prior) => {
                return {
                  ...prior,
                  [e.target.name]: e.target.value,
                };
              });
            }}
          />
          <input
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-[48%] lg:ml-3 ml-2"
            type="text"
            name="last_name"
            id="last_name"
            placeholder={t("modal.register_user.last_name")}
            required
            value={userDetails.last_name}
            onChange={(e) => {
              setUserDetails((prior) => {
                return {
                  ...prior,
                  [e.target.name]: e.target.value,
                };
              });
            }}
          />

          <input
            className="bg-[#F2F0F8] px-[20px] py-[16px] mb-5 rounded-lg w-[48%]"
            type="text"
            name="user_email"
            id="user_email"
            placeholder={t("modal.register_user.email")}
            required
            value={userDetails.user_email}
            onChange={(e) => {
              setUserDetails((prior) => {
                return {
                  ...prior,
                  [e.target.name]: e.target.value,
                };
              });
            }}
          />
          <button
            disabled={loadingData}
            type="submit"
            className="disabled:opacity-20 button bg-secondary border-0 w-full text-black py-4 font-medium"
          >
            {t("modal.register_user.cta")}
          </button>
        </form>
      </div>
    </div>
    <div className=""></div>
  </>
);

const SurveyResultsSubCategoryBox = ({
  item,
  parent_title = null,
  skill_ind = null,
  academylink,
  t,
}) => (
  <div className="survey-box-sub-category">
    <span className={`subcategory-image bg-${parent_title}`}>
      <img
        src={imageUrl + item.subcategory?.icon?.url}
        alt={item.subcategory?.title}
        className="icon-inner-img"
      />
    </span>
    <div className="align-middle inline-block max-w-[calc(100%-80px)]">
      <h4 className="font-inter tracking-[.08em]">
        {t("surveyResults.subcategory.title")} {skill_ind + 1 < 10 ? "0" : ""}
        {skill_ind + 1}
      </h4>
      <h3 className="title-xl">{item.subcategory.title}</h3>
    </div>
    <div className="mt-3">
      <span className="text-sm mb-1 block">
        {item.subcategory.questions[0].answer?.rating > 0
          ? item.subcategory.questions[0].answer?.rating % 1 === 0
            ? item.subcategory.questions[0].answer?.rating
            : item.subcategory.questions[0].answer?.rating?.toFixed(1)
          : 0}
        <span className={`text-${parent_title}`}> / </span>
        {item.subcategory.questions[0].answer.possible_score_range}
      </span>
      <div
        className={`progress-bar inline-block align-middle w-[calc(100%-130px)] bg-${parent_title}-light`}
      >
        <span
          style={{
            width: `calc(${item.subcategory.questions[0].answer.rating}*100%/${item.subcategory.questions[0].answer.possible_score_range})`,
          }}
          className={`bg-${parent_title}`}
        ></span>
        {item.subcategory.questions[0].answer.self_rating > 0 && (
          <span
            className="self-assess-mark"
            style={{
              left: `calc(${item.subcategory.questions[0].answer.self_rating}*100%/${item.subcategory.questions[0].answer.possible_score_range})`,
            }}
          >
            <span className="tooltip">
              {t("surveyResults.subcategory.tooltip.self-assess")}{" "}
              {item.subcategory.questions[0].answer.self_rating}
            </span>
          </span>
        )}
      </div>

      {academylink && (
        <a
          href={`${academylink}/${item.subcategory.questions[0].answer.academy_content_id}`}
          target="_blank"
          rel="noreferrer"
          className="button rounded-[7px] bg-secondary text-primary border-none ml-8 py-[5px] w-[93px] leading-[16px] text-sm"
        >
          {t("surveyResults.subcategory.academy-cta")}
        </a>
      )}
    </div>
    <div className="question-box">
      <strong className="block mb-1 text-black">
        {t("surveyResults.subcategory.question_title")}
      </strong>
      {item.subcategory.questions[0].question.text}
    </div>
  </div>
);

export {
  SurveyTabs,
  SurveyResultsSubCategoryBox,
  SurveySummaryResults,
  SurveyResultsBox,
  SurveyTextQuestionBox,
  ModalCategories,
  ModalUserRegister,
  ModalPartnerUserRegister,
};
