import React from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useLayoutEffect } from "react";
import Home from "./pages/Home";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./pages/Login";
import LoginRedirect from "./pages/LoginRedirect";
import SurveyConf from "./pages/SurveyConf";
import SurveySteps from "./pages/SurveySteps";
import PartnerSurveySteps from "./pages/PartnerSurveySteps";
import PartnerSurveyResults from "./pages/PartnerSurveyResults";
import SurveyResults from "./pages/SurveyResults";
import ContentCard from "./pages/ContentCard";
import Admin from "./pages/Admin";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  const notify = (message, type = null) => {
    if (type === "success") {
      toast.success(message);
    } else if (type === "error") {
      toast.error(message);
    } else if (type === "info" || type === null) {
      toast(message);
    }
  };
  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route element={<ProtectedRoute notify={notify} />}>
              <Route path="/" element={<Home />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/survey/conf" element={<SurveyConf />} />
              <Route path="/survey/results" element={<SurveyResults />} />
              <Route path="/academy/:id" element={<ContentCard />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/connect/auth0" element={<LoginRedirect />} />
            <Route
              path="/self-survey/:survey_name"
              element={<SurveySteps type="self" />}
            />
            <Route
              path="/user-survey/:survey_name/:user_survey_id"
              element={<SurveySteps type="user" />}
            />
            <Route
              path="/user-survey/:survey_name/"
              element={<SurveySteps type="user" />}
            />
            <Route
              path="/partner-survey/:survey_name?/:user_email?"
              element={<PartnerSurveySteps notify={notify} type="partner" />}
            />
            <Route
              path="/partner-survey/results/:anon_request?"
              element={<PartnerSurveyResults type="partner" notify={notify} />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
};

export default App;
