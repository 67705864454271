import { useState, useCallback, useEffect } from "react";
import Header from "../modules/Header";
import FooterInner from "../modules/FooterInner";
import SurveyStep1 from "../modules/SurveyStep1";
import SurveyStep2 from "../modules/SurveyStep2";
import SurveyStep3 from "../modules/SurveyStep3";
import SurveyStep4 from "../modules/SurveyStep4";

import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";

import { useParams } from "react-router-dom";
import {
  axiosGetSurveyRoute,
  axiosSubmitSurveyRoute,
  axiosSelfSurveyRoute,
} from "../helpers/axios";

const cookies = new Cookies();

const SurveySteps = ({ type }) => {
  const [stepNumber, setStepNumber] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [surveyData, setSurveyData] = useState();
  const [surveyErrorResponse, setSurveyErrorResponse] = useState("");
  const [surveyAnswers, setSurveyAnswers] = useState([]);

  const [firstRun, setFirstRun] = useState(true);

  const token = cookies.get("veemind-app-token");

  const usertoken = cookies.get("veemind-user-survey");

  const { t } = useTranslation();

  let { survey_name, user_survey_id } = useParams();

  const getSurvey = useCallback(
    async (id_survey, id_user_survey, type = "user", t) => {
      try {
        let result;
        if (type === "self") {
          result = await axiosSelfSurveyRoute({
            data: { surveyid: id_survey },
            ...(token && {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }),
          });
        } else {
          result = await axiosGetSurveyRoute(`/${id_survey}`, {
            params: {
              ...(id_user_survey && { id_user_survey: id_user_survey }),
            },
          });
        }

        if (result.data) {
          setSurveyData(result.data);

          if (result.data.findusersurvey) {
            let answerobj = {};

            result.data.findusersurvey.answers.forEach((answer) => {
              return (answerobj = {
                ...answerobj,
                [answer.question_id.id]: answer.response,
              });
            });

            setSurveyAnswers(answerobj);
          }
        }
        setFirstRun(false);
      } catch (err) {
        setSurveyErrorResponse(err.response.data.error.message);
        console.log(err.response.data.error.message);
      }
    },
    [token]
  );

  const submitSurveyHandler = useCallback(
    async ({final_step = false, save_per_category = false}) => {
      try {
        const result = await axiosSubmitSurveyRoute({
          data: {
            user_survey_id: surveyData?.findusersurvey?.id || null,
            survey_id: surveyData.id,
            answers: surveyAnswers,
            survey_type: surveyData.type,
            survey_complete: final_step,
          },
          ...(token && {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        });

        if (result.data && result.data.findusersurvey) {
          const usersurveydata = result.data.findusersurvey;
          let answerobj = {};

          setSurveyData((prior) => ({
            ...prior,
            findusersurvey: usersurveydata,
          }));

          usersurveydata.answers.forEach((answer) => {
            answerobj = {
              ...answerobj,
              [answer.question_id.id]: answer.response,
            };
          });

          setSurveyAnswers(answerobj);
          if(!save_per_category)
            setStepNumber((currentstep) => Number(currentstep) + 1);

          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 15);
          cookies.set(
            "veemind-user-survey",
            result.data.findusersurvey.anon_request,
            {
              path: "/",
              expires: expirationDate,
            }
          );

          // if (
          //   surveyData.type === "user" &&
          //   !surveyData.invite_based &&
          //   result.data.findusersurvey.survey_complete
          // ) {
          //   const expirationDate = new Date();
          //   expirationDate.setDate(expirationDate.getDate() + 15);
          //   cookies.set("veemind-user-survey", surveyData.survey_slug, {
          //     path: "/",
          //     expires: expirationDate,
          //   });
          // }
          window.scrollTo(0, 0);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [surveyAnswers, surveyData, token]
  );

  useEffect(() => {
    firstRun && getSurvey(survey_name, user_survey_id || usertoken, type);
  }, [getSurvey, survey_name, user_survey_id, usertoken, type, firstRun]);

  const gatherSurveyAnswers = (item, selection) => {
    setSurveyAnswers((values) => ({
      ...values,
      [item.id]: selection,
    }));
  };

  return (
    <div>
      <Header mode={"usersurvey"} />
      {surveyData && (
        <>
          {!stepNumber && (
            <SurveyStep1
              surveyData={surveyData}
              setStepNumber={setStepNumber}
            />
          )}
          {stepNumber === 2 && (
            <SurveyStep2
              surveyData={surveyData.questions?.filter((filtertype) => filtertype.type !== "Input")}
              surveyAnswers={surveyAnswers}
              submitSurveyHandler={submitSurveyHandler}
              gatherSurveyAnswers={gatherSurveyAnswers}
              categories={Object.keys(surveyData?.questions_sorted).filter((filtertype) => filtertype !== "Team feedback")}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
            />
          )}
          {stepNumber === 3 && (
            <SurveyStep3
              surveyType={surveyData.type}
              surveyData={surveyData.questions}
              surveyAnswers={surveyAnswers}
              setStepNumber={setStepNumber}
              gatherSurveyAnswers={gatherSurveyAnswers}
              submitSurveyHandler={submitSurveyHandler}
            />
          )}
          {stepNumber === 4 && (
            surveyData?.questions?.filter((filtertype) => filtertype.type === "Input").length>0 ?(
            <SurveyStep4
              surveyData={surveyData}
              setStepNumber={setStepNumber}
            />
          ):(
            window.location = "/survey/results"
          ))}
        </>
      )}
      {surveyErrorResponse && (
        <section className="lg:h-screen flex flex-wrap justify-center overflow-hidden lg:px-0 px-[15px] pt-[70px] lg:pb-[70px] dark:bg-dark-login">
          <div className="w-full mx-auto self-center text-center py-[60px]">
            <h2 className="font-semibold text-[40px] leading-[45px] text-primary dark:text-white mb-7">
              {t(surveyErrorResponse)}
            </h2>

              <section className="lg:h-screen flex flex-wrap justify-center overflow-hidden lg:px-0 px-[15px] pt-[70px] lg:pb-[70px] dark:bg-dark-login">
              <div>
              <a className="bg-secondary text-black border-secondary button block w-full mb-10 py-[15px]"
          href="/login"
              >{t('login.cta_after_error')}</a>
              </div>
              </section>
          </div>
        </section>
      )}
      <FooterInner />
    </div>
  );
};

export default SurveySteps;
