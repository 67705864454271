import { useState, useCallback } from "react";
import Header from "../modules/Header";
import { useOutletContext, useNavigate, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { axiosSurveyRoute } from "../helpers/axios";
const imageUrl = process.env.REACT_APP_IMAGE_URL;

const SurveyConf = () => {
  const {
    userDetails,
    selectedTemplate,
    token,
    getSurveyResults,
    surveyResults,
  } = useOutletContext();

  const [toggleActive, setToggleActive] = useState();
  const { t } = useTranslation();

  // used when customization for elements is defined. For now, all elements of survey are selected by default.

  // In future circumstance where user can selected specific questions, question id will be added or removed with sublogic
  const [selectedQuestions /*setSelectedQuestions*/] = useState(
    selectedTemplate?.categories?.data?.flatMap((category) =>
      category?.attributes?.questions?.data?.map((question) => question.id)
    )
  );

  const navigate = useNavigate();

  const SurveyConfSubCategoryBox = ({
    item,
    parent_title = null,
    skill_ind = null,
  }) => (
    <div className="survey-box-sub-category">
      <span className={`subcategory-image bg-${parent_title}`}>
        <img
          src={
            imageUrl +
            item.attributes.categories.data[1].attributes.icon?.data?.attributes
              ?.url
          }
          alt={item.subcategory?.title}
          className="icon-inner-img"
        />
      </span>
      <div className="align-middle inline-block max-w-[calc(100%-80px)]">
        <h4 className="font-inter tracking-[.08em]">
          {t("surveyConf.subcategory.title")} {skill_ind + 1 < 10 ? "0" : ""}
          {skill_ind + 1}
        </h4>
        <h3 className="title-2xl">
          {item.attributes.categories.data[1].attributes.title}
        </h3>
      </div>
      <div className="question-box">
        <strong className="block mb-1 text-black">
          {t("surveyConf.subcategory.question_title")}
        </strong>
        {item.attributes.text}
      </div>
    </div>
  );
  const SurveyConfBox = ({ item }) => (
    <div
    onClick={(e) => {
      setToggleActive((prior) => {
        return prior === item.title ? null : item.title;
      });
    }}
    className={`survey-box cursor-pointer element-${item.title}${
        toggleActive === item.title ? " active" : ""
      }`}
    >
      <span className="survey-image">
        <img
          src={imageUrl + item.icon?.data?.attributes?.url}
          alt={item.title}
        />
      </span>
      <div className="inline-block align-middle lg:max-w-[calc(100%-125px)] max-w-[calc(100%-85px)]">
        <h4 className="subtitle">{t("surveyConf.category.title")}</h4>
        <h3 className="title-2xl">{item.title}</h3>
        <p className="title-sm">{item.text}</p>
      </div>
    </div>
  );
  const ModalCategories = ({ item }) => (
    <div className={`modal modal-${item.attributes.title}`}>
      <div
        className="overlay"
        onClick={(e) => {
          setToggleActive((prior) => {
            return null;
          });
        }}
      ></div>
      <div className="modal-body">
        <button
          className="close-button"
          onClick={(e) => {
            setToggleActive((prior) => {
              return null;
            });
          }}
        >
          X
        </button>
        <div className="modal-overflow">
          <div className="modal-inner">
            <div
              className={`survey-box element-${item.attributes.title} active `}
              id={item.attributes.title}
            >
              <span className="survey-image">
                <img
                  src={imageUrl + item.attributes?.icon?.data?.attributes?.url}
                  alt={item.attributes?.title}
                />
              </span>
              <div className="inline-block align-middle lg:max-w-[calc(100%-125px)] max-w-[calc(100%-85px)]">
                <h4 className="subtitle">{t("surveyConf.category.title")}</h4>
                <h3 className="title-2xl">{item.attributes.title}</h3>
                <p className="title-sm">{item.attributes.text}</p>
              </div>
            </div>
            {item.attributes.questions.data &&
              item.attributes.questions.data.map((subitem, skill_ind) => {
                return (
                  <SurveyConfSubCategoryBox
                    // key={subitem.id}
                    item={subitem}
                    parent_title={item.attributes.title}
                    skill_ind={skill_ind}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
  const createSurveyHandler = useCallback(async () => {
    const data = {
      data: {
        title: selectedTemplate?.title,
        // categories: selectedTemplateElements,
        questions: selectedQuestions,
        user: userDetails.id,
      },
    };

    try {
      const result = await axiosSurveyRoute({
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.data) {
        await getSurveyResults(userDetails.id);
        navigate("/survey/results");
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    token,
    getSurveyResults,
    selectedTemplate,
    selectedQuestions,
    userDetails,
    navigate,
  ]);

  return (
    <div>
      <Header />
      {surveyResults?.id ? (
        <Navigate to={{ pathname: "/survey/results" }} />
      ) : (
        <>
          <section className="container lg:px-0 flex flex-wrap py-[140px] mx-auto lg:w-[955px] survey-conf">
            <div className="lg:w-[calc(50%-17px)] mb-[34px] self-end">
              <h2 className="title-primary mb-2">
                {t("surveyConf.page_title")}
              </h2>
              <p className="subtitle">{t("surveyConf.page_text")}</p>
            </div>
            <div className="box-primary survey-box lg:pr-[10%] lg:pt-6">
              <h3 className="font-bold text-sm mb-4">
                {t("surveyConf.box.title")}
              </h3>
              <p className="mb-4">{t("surveyConf.box.text_1")}</p>
              <p>{t("surveyConf.box.text_2")}</p>
            </div>
            {selectedTemplate.categories.data
              .filter(
                (category) => category.attributes.title !== "Team feedback"
              )
              .map((item) => {
                return <SurveyConfBox key={item.id} item={item.attributes} />;
              })}
          </section>
          <section className="fixed bottom-0 left-0 w-full bg-primary text-white py-8 z-10">
            <div className="container lg:px-0 flex flex-wrap mx-auto lg:w-4/5">
              <div className="self-center w-1/4">
                <span className="inline-block w-1/3 align-middle">
                  {t("surveyConf.stats.time")}
                </span>
                <span className="inline-block align-middle ml-4 title-2xl mr-1">
                  {Math.floor(selectedQuestions.length / 2.2)}
                </span>
                {t("surveyConf.minutes")}
              </div>
              <div className="self-center w-1/4">
                <span className="inline-block w-1/3 align-middle">
                  {t("surveyConf.stats.categories")}
                </span>
                <span className="inline-block align-middle ml-4 title-2xl">
                  {
                    selectedTemplate.categories?.data.filter(
                      (category) => category.id !== 22
                    ).length
                  }
                </span>
              </div>
              <div className="self-center w-1/4">
                <span className="inline-block w-1/3 align-middle">
                  {t("surveyConf.stats.questions")}
                </span>
                <span className="inline-block align-middle ml-4 title-2xl">
                  {selectedQuestions.length}
                </span>
              </div>
              <div className="self-center w-1/4">
                <button
                  onClick={createSurveyHandler}
                  id="event-1-initialize-survey"
                  className="button bg-secondary border-0 xl:w-[335px] lg:w-[280px] w-full text-black py-4 font-medium"
                >
                  {t("surveyConf.stats.cta")}
                </button>
              </div>
            </div>
          </section>
          {toggleActive !== null &&
            toggleActive !== undefined &&
            selectedTemplate.categories.data.map((item, ind) => {
              return (
                toggleActive === item.attributes.title && (
                  <ModalCategories item={item} />
                )
              );
            })}
        </>
      )}
    </div>
  );
};

export default SurveyConf;
