import { useState, useCallback, useEffect } from "react";

import Header from "../modules/Header";
import Footer from "../modules/Footer";
import Img from "../images/content-card.jpg";
// import Img2 from "../images/marketing.jpg";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import { useParams } from "react-router-dom";

import Cookies from "universal-cookie";

import { axiosGetAcademyRoute } from "../helpers/axios";

const cookies = new Cookies();

const imageUrl = process.env.REACT_APP_IMAGE_URL;

// const ElementsData = [
//   {
//     title: "Marketing Content",
//     img: Img2,
//     text: "Have you ever thought about involving employees in decision making? The success of your business",
//     url: "#",
//   },
//   {
//     title: "Marketing Content",
//     img: Img2,
//     text: "Have you ever thought about involving employees in decision making? The success of your business",
//     url: "#",
//   },
//   {
//     title: "Marketing Content",
//     img: Img2,
//     text: "Have you ever thought about involving employees in decision making? The success of your business",
//     url: "#",
//   },
//   {
//     title: "Marketing Content",
//     img: Img2,
//     text: "Have you ever thought about involving employees in decision making? The success of your business",
//     url: "#",
//   },
// ];

// const CardItemBox = ({ item, t }) => (
//   <div className="lg:w-1/4 lg:px-3 mb-6">
//     <div className="box-white py-5 px-4 inline-block">
//       <h3 className="title-primary-base mb-5 text-center">{item.title}</h3>
//       <img
//         src={item.img}
//         className="w-full h-auto rounded-[15px] mb-4"
//         alt={item.title}
//       />
//       <p className="text-sm text-[#585371] mb-5">{item.text}</p>
//       <a
//         href={item.url}
//         className="button border-0 font-medium bg-secondary text-primary float-right"
//       >
//         {t("academy.read-more")}
//       </a>
//     </div>
//   </div>
// );

const ContentCard = () => {
  const [academyContent, setAcademyContent] = useState();

  const token = cookies.get("veemind-app-token");

  const { t } = useTranslation();

  let { id } = useParams();

  const getSurvey = useCallback(
    async (id) => {
      try {
        let result;

        result = await axiosGetAcademyRoute(`/${id}`, {
          params: {
            "populate[categories][populate][icon]": "*",
            populate: "header_image",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data) {
          console.log(result.data);
          setAcademyContent(result.data);
        }
      } catch (err) {
        console.log(err.response.data.error.message);
      }
    },
    [token]
  );

  useEffect(() => {
    getSurvey(id);
  }, [getSurvey, id]);
  const main_category =
    academyContent?.data?.attributes?.categories?.data.filter(
      (item) => item.attributes.type === "main"
    )[0].attributes;
  const sub_category =
    academyContent?.data?.attributes?.categories?.data.filter(
      (item) => item.attributes.type === "subcategory"
    )[0].attributes;
  const exercises_content = academyContent?.data?.attributes?.exercises;
  const reading_time = Math.round(
    academyContent?.data?.attributes?.exercises?.split(" ").length / 200
  );

  return (
    <div>
      <Header />
      {academyContent && (
        <section className="content-card">
          <div className="container lg:px-0 flex flex-wrap pt-[170px] pb-[80px] mx-auto lg:w-[971px]">
            <div className="lg:w-1/2 w-full lg:pr-10 lg:mb-[90px] mb-[34px]">
              <span
                className="card-image"
                style={{
                  backgroundImage:
                    "url(" +
                    imageUrl +
                    academyContent?.data?.attributes?.header_image?.data
                      ?.attributes?.url +
                    ")",
                }}
              ></span>
            </div>

            <div className="lg:w-1/2 w-full lg:pl-10 lg:mb-[90px] mb-[34px] self-center">
              <h1 className="card-title">
                {academyContent.data.attributes.core_message}
              </h1>
            </div>

            <div className="w-full box-white font-inter flex flex-wrap px-0 mb-7 lg:py-7 py-0">
              <div className="lg:w-1/3 w-full px-10 lg:py-0 py-10">
                <span
                  className={`icon-border-rounded icon-lg icon-${main_category.title}`}
                >
                  <img
                    src={imageUrl + main_category.icon?.data?.attributes?.url}
                    alt={main_category.title}
                    className="icon-inner-img"
                  />
                </span>
                <div className="inline-block align-top pl-7 lg:max-w-[calc(100%-70px)]">
                  <span className="block pl-1 mb-1 text-[#676767]">
                    {t("academy.category.title")}
                  </span>
                  <h3 className="title-primary-lg">{main_category.title}</h3>
                </div>
              </div>
              <div className="lg:w-1/3 w-full px-10 lg:py-0 py-10 relative after:content-[''] after:absolute lg:after:left-0 after:left-10 lg:after:w-[1px] lg:after:h-auto after:h-[1px] lg:after:bottom-0 lg:after:right-auto after:right-10 after:bg-[#3A3A3A] after:top-0 after:opacity-25">
                <span className={`bg-${main_category.title} align-top icon-lg`}>
                  <img
                    src={imageUrl + sub_category.icon?.data?.attributes?.url}
                    alt={sub_category.title}
                    className="icon-inner-img"
                  />
                </span>
                <div className="inline-block align-middle pl-7 lg:max-w-[calc(100%-70px)]">
                  <span className="block pl-1 mb-1 text-[#676767] ">
                    {t("academy.subcategory.title")}
                  </span>
                  <h3 className="title-primary-lg" lang="en">
                    {sub_category.title}
                  </h3>
                </div>
              </div>
              <div className="lg:w-1/3 w-full px-10 lg:py-0 py-10 relative after:content-[''] after:absolute lg:after:left-0 after:left-10 lg:after:w-[1px] lg:after:h-auto after:h-[1px] lg:after:bottom-0 lg:after:right-auto after:right-10 after:bg-[#3A3A3A] after:top-0 after:opacity-25">
                <span className="icon-clock icon-lg"></span>
                <div className="inline-block align-middle pl-7 lg:max-w-[calc(100%-70px)]">
                  <span className="block pl-1 mb-1 text-[#676767]">
                    {t("academy.reading_time")}
                  </span>
                  <h3 className="title-primary-lg">
                    {reading_time > 0 ? reading_time : 1} {t("academy.minutes")}
                  </h3>
                </div>
              </div>
            </div>

            <div className="w-full box-white mb-7 px-11">
              <h2 className="border-title">{t("academy.intro.title")}</h2>
              <p className="text-lg text-[#585371]">
                {academyContent.data.attributes.introduction}
              </p>
            </div>

            <div className="w-full box-white mb-7 px-11 dark:bg-[rgba(255,255,255,0.1)]">
              <h2 className="border-title dark:text-white dark:border-white">
                {t("academy.content.title")}
              </h2>
              <div className="card-content">
                <ReactMarkdown>{exercises_content}</ReactMarkdown>
              </div>
            </div>
            {/*ElementsData && (
              <div className="flex flex-wrap lg:-mx-3 lg:w-[calc(100%+24px)] w-full">
                {ElementsData.map((item) => {
                  return <CardItemBox item={item} t={t} />;
                })}
              </div>
              )*/}
          </div>
        </section>
      )}
      <Footer position="static" />
    </div>
  );
};

export default ContentCard;
