import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";
import mime from "mime";
import Cookies from "universal-cookie";

const Settings = () => {
  const {
    userDetails,
    getAllCompanyData,
    createCompany,
    createPartnerSurvey,
    frontendurl,
    notify,
    deleteSurvey,
    modifyCompany,
    backendurl,
    // deleteCompany,
  } = useOutletContext();

  const [editedCompanyData, setEditedCompanyData] = useState({});
  const [allCompanyData, setAllCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  const cookies = new Cookies();

  const logOut = () => {
    cookies.remove("veemind-app-token", { path: "/" });
    window.location.href = `https://${process.env.REACT_APP_AUTH0_SUBDOMAIN}.auth0.com/v2/logout?returnTo=${process.env.REACT_APP_FRONTEND_URL}&client_id=${process.env.REACT_APP_AUTH0_CLIENTID}`;
  };

  const getCompanyData = useCallback(async () => {
    const results = await getAllCompanyData();
    setAllCompanyData(results);
  }, [getAllCompanyData, setAllCompanyData]);

  const handleeditedCompanyDataChange = (name, value) => {
    setEditedCompanyData((prevData) => ({
      ...prevData,
      attributes: {
        ...prevData.attributes,
        [name]: value,
      },
    }));
  };

  const removeLogo = () => {
    // Remove the logo preview by setting it to null
    setLogoPreview(null);

    // Clear the file input value to reset it
    const fileInput = document.getElementById("logoInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const formatCompanyData = async () => {
    const data = {};
    const formData = new FormData();

    for (const key in editedCompanyData.attributes) {
      // Skip the logo key, we'll handle that separately

      if (key === "logo") {
        continue;
      }

      data[key] = editedCompanyData.attributes[key];
    }

    data.partner = true;

    if (logoPreview) {
      // logo preview is a base64 string, convert it to a blob
      const blob = await fetch(logoPreview).then((r) => r.blob());

      const mimeType = blob.type;

      // Determine the file extension from the MIME type
      const extension = mime.getExtension(mimeType);
      formData.append("files.logo", blob, `logo.${extension}`);
    }

    formData.append("data", JSON.stringify(data));

    return formData;
  };

  const isInputValid = (value) => value?.length >= 2;
  const isValidEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const getErrorMessages = () => {
    const errors = {};
    for (const key in editedCompanyData.attributes) {
      if (key === "email" && !isValidEmail(editedCompanyData.attributes[key])) {
        errors[key] = "Please enter a valid email";
      } else if (
        (key === "name" || key === "contact") &&
        !isInputValid(editedCompanyData.attributes[key])
      ) {
        errors[key] = `This field must have at least 2 characters`;
      }
    }
    return errors;
  };

  const errorMessages = getErrorMessages();

  const createNewCompany = async () => {
    const formData = await formatCompanyData();
    try {
      await createCompany(formData);
      setEditedCompanyData({});
      removeLogo();
      getCompanyData();
      notify("Company Created", "success");
    } catch (error) {
      error.forEach((err) => notify(err.message, "error"));
    }
  };

  const createNewPartnerSurvey = async () => {
    const surveyData = {
      company_id: selectedCompany.id,
    };

    await createPartnerSurvey(surveyData);

    getCompanyData();
    notify("Survey Created", "success");
  };

  const openConfirmationDialog = (id) => {
    setSurveyToDelete(id);
    setIsConfirmationOpen(true);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationOpen(false);
    setSurveyToDelete(null);
  };

  const deleteSurveyHandler = async () => {
    if (surveyToDelete) {
      await deleteSurvey(surveyToDelete);
      getCompanyData();
      notify("Survey Deleted", "success");
      closeConfirmationDialog();
    }
  };

  const modifySelectedCompany = async () => {
    const formData = await formatCompanyData();
    // Modify the selected company with editedCompanyData or any other logic
    await modifyCompany(formData, selectedCompany.id);
    removeLogo();
    getCompanyData();
    notify("Company Updated", "success");
  };

  useEffect(() => {
    if (userDetails) {
      getCompanyData();
    }
  }, [userDetails, getCompanyData]);

  useEffect(() => {
    if (allCompanyData?.data?.length > 0 && selectedCompany) {
      const findCurrentCompany = allCompanyData?.data?.find(
        (company) => String(company.id) === String(selectedCompany.id)
      );

      setSelectedCompany(findCurrentCompany);
    }
  }, [allCompanyData, selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setEditedCompanyData(selectedCompany);
    } else {
      setEditedCompanyData({});
    }
  }, [selectedCompany]);

  const ConfirmationDialog = ({ isOpen, onCancel, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <p className="mb-4">Are you sure you want to delete this survey?</p>
          <p className="mb-4">
            This will delete all responses associated with this survey as well.
          </p>
          <div className="flex justify-end">
            <button
              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 mr-2"
              onClick={onConfirm}
            >
              Confirm
            </button>
            <button
              className="bg-gray-300 text-gray-800 px-3 py-1 rounded hover:bg-gray-400"
              onClick={onCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleLogoUpload = (e) => {
    if (!e.target.files[0]) {
      setLogoPreview(null);
      return;
    }

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setLogoPreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="bg-gray-300 min-h-screen flex flex-col justify-center items-center">
      <div className="bg-white rounded-lg p-6 shadow-xl w-2/3 overflow-auto my-20">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold mb-6">Admin Panel</h1>
          <div className="text-right mb-4">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
              onClick={logOut}
            >
              Logout
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="mb-6">
            <h2 className="text-md font-semibold mb-4">
              {selectedCompany ? "Edit Company" : "Create New Company"}
            </h2>
            <div className="bg-white p-4 rounded-lg">
              <div className="flex items-end justify-end w-full">
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                  onClick={() => {
                    setSelectedCompany(null);
                    setEditedCompanyData({});
                    removeLogo();
                  }}
                >
                  New Company
                </button>
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1">Company Name</label>
                <input
                  type="text"
                  className="border-2 rounded p-2 w-full"
                  name="name"
                  value={editedCompanyData?.attributes?.name || ""}
                  onChange={(e) =>
                    handleeditedCompanyDataChange(e.target.name, e.target.value)
                  }
                />
                {errorMessages.name && (
                  <p className="text-red-500 text-sm mt-1">
                    {errorMessages.name}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1">Email</label>
                <input
                  type="text"
                  className="border-2 rounded p-2 w-full"
                  name="email"
                  value={editedCompanyData?.attributes?.email || ""}
                  onChange={(e) =>
                    handleeditedCompanyDataChange(e.target.name, e.target.value)
                  }
                />
                {errorMessages.email && (
                  <p className="text-red-500 text-sm mt-1">
                    {errorMessages.email}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1">Contact Name</label>
                <input
                  type="text"
                  className="border-2 rounded p-2 w-full"
                  name="contact"
                  value={editedCompanyData?.attributes?.contact || ""}
                  onChange={(e) =>
                    handleeditedCompanyDataChange(e.target.name, e.target.value)
                  }
                />
                {errorMessages.contact && (
                  <p className="text-red-500 text-sm mt-1">
                    {errorMessages.contact}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1">Tag Line</label>
                <input
                  type="text"
                  className="border-2 rounded p-2 w-full"
                  name="tag_line"
                  value={editedCompanyData?.attributes?.tag_line || ""}
                  onChange={(e) =>
                    handleeditedCompanyDataChange(e.target.name, e.target.value)
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1">
                  Survey Text Supplement (Markdown)
                </label>
                <MDEditor
                  name="survey_text_supplement"
                  value={
                    editedCompanyData?.attributes?.survey_text_supplement || ""
                  }
                  onChange={(value) =>
                    handleeditedCompanyDataChange(
                      "survey_text_supplement",
                      value
                    )
                  }
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-1">
                  Current Logo
                  {selectedCompany?.attributes?.logo?.url && (
                    <span className="text-xs"> (Click to open in new tab)</span>
                  )}
                </label>

                {selectedCompany?.attributes?.logo?.data?.attributes?.url ? (
                  <img
                    src={
                      backendurl +
                      selectedCompany.attributes.logo.data.attributes.url
                    }
                    alt="Logo"
                    className="border-2 rounded p-2 cursor-pointer"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                ) : (
                  <span>No Logo</span>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm mb-1">New Logo</label>
                <input
                  type="file"
                  className="border-2 rounded p-2 w-full"
                  name="logo"
                  id="logoInput"
                  accept="image/*"
                  onChange={handleLogoUpload}
                />
              </div>

              {/* Display the logo preview */}
              {logoPreview && (
                <div className="mb-4">
                  <label className="block text-sm mb-1">Logo Preview</label>
                  <div className="flex items-start">
                    <img
                      src={logoPreview}
                      alt="Logo Preview"
                      className="border-2 rounded p-2"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                    <button
                      className="ml-2 bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                      onClick={removeLogo}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
              {selectedCompany ? (
                <button
                  disabled={
                    Object.keys(errorMessages).length > 0 ||
                    !editedCompanyData.attributes?.name ||
                    !editedCompanyData.attributes?.email ||
                    !editedCompanyData.attributes?.contact
                  }
                  className="disabled:opacity-20 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                  onClick={modifySelectedCompany}
                >
                  Update Company
                </button>
              ) : (
                <button
                  disabled={
                    Object.keys(errorMessages).length > 0 ||
                    !editedCompanyData.attributes?.name ||
                    !editedCompanyData.attributes?.email ||
                    !editedCompanyData.attributes?.contact
                  }
                  type="submit"
                  className="disabled:opacity-20 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300"
                  onClick={createNewCompany}
                >
                  Create New Company
                </button>
              )}
            </div>
          </div>
          <div className="mb-6">
            <h2 className="text-md font-semibold mb-4">Company Data</h2>
            <div
              className="bg-white p-4 rounded-lg"
              style={{ height: "350px" }}
            >
              <div className="mb-4">
                <label className="block text-sm mb-2 mt-2">
                  Select a Company
                </label>
                <select
                  className="border-2 rounded p-2 w-full"
                  value={selectedCompany?.id || ""}
                  onChange={(e) => {
                    const findCurrentCompany = allCompanyData?.data?.find(
                      (company) => String(company.id) === String(e.target.value)
                    );
                    setSelectedCompany(findCurrentCompany);
                  }}
                >
                  <option disabled value={""}>
                    Select a Company
                  </option>
                  {allCompanyData?.data?.length > 0 &&
                    allCompanyData.data.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company?.attributes?.name || "No Name"}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-4">
                <div className="flex justify-between">
                  <div className="font-semibold mb-2">Company Surveys</div>
                  <button
                    disabled={!selectedCompany}
                    className="disabled:opacity-20 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                    onClick={createNewPartnerSurvey}
                  >
                    Create Survey
                  </button>
                </div>

                {selectedCompany?.attributes?.surveys?.data?.length > 0 ? (
                  <div className="space-y-4">
                    {selectedCompany.attributes.surveys.data.map((survey) => (
                      <div
                        key={survey.id}
                        className="border-b-2 pb-2 flex justify-between items-center"
                      >
                        <div>
                          <div className="text-sm mb-1">
                            {survey.attributes.title}
                          </div>

                          <div className="space-x-2">
                            <button
                              className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition duration-300"
                              onClick={() => {
                                const surveySlug =
                                  survey.attributes.survey_slug;
                                const surveyUrl = `${frontendurl}/partner-survey/${surveySlug}`;
                                window.open(surveyUrl, "_blank");
                              }}
                            >
                              Open
                            </button>
                            <button
                              className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition duration-300"
                              onClick={() => {
                                // Copy to clipboard
                                const surveySlug =
                                  survey.attributes.survey_slug;
                                const surveyUrl = `${frontendurl}/partner-survey/${surveySlug}`;
                                navigator.clipboard.writeText(surveyUrl);
                                notify("Copied", "success");
                              }}
                            >
                              Copy Link
                            </button>
                            <button
                              className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-300"
                              onClick={() => {
                                openConfirmationDialog(survey.id);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <span>No Surveys</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {surveyToDelete && (
        <ConfirmationDialog
          isOpen={isConfirmationOpen}
          onCancel={closeConfirmationDialog}
          onConfirm={deleteSurveyHandler}
        />
      )}
    </div>
  );
};

export default Settings;
