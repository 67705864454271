import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SurveyStep4 = ({ surveyType, partnerLink }) => {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    let redirectTimer;

    if (surveyType === "partner" && partnerLink) {
      // Set a timer to decrement the countdown every second
      redirectTimer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      // Clear the timer if the component unmounts
      clearInterval(redirectTimer);
    };
  }, [surveyType, partnerLink]);

  useEffect(() => {
    // If redirectToPartner is true, navigate to partnerLink
    if (countdown === 0) {
      window.location = partnerLink;
    }
  }, [countdown, partnerLink]);

  return (
    <section className="container pt-[140px] lg:h-[90vh] mx-auto text-center lg:max-w-[742px]">
      <div className="icon-confetti mb-5"></div>
      <div className="w-full text-center xl:mb-20 mb-16">
        <h2 className="title-primary mb-6">{t("step4.title")}</h2>
        <p className="subtitle">{t("step4.text")}</p>
      </div>
      {surveyType !== "partner" && (
        <div className="box-primary text-left">
          <p className="inline-block align-middle max-w-[59%]">
            <strong>{t("step4.teasertitle")}</strong> {t("step4.teasertext")}
          </p>
          <a
            href="/survey/"
            className="button bg-secondary align-middle border-0 lg:w-[218px] w-full text-black py-4 font-medium lg:ml-11"
          >
            {t("step4.cta")}
          </a>
        </div>
      )}

      {surveyType === "partner" && (
        <div className="bg-gray-200 p-4 border border-gray-300 rounded text-center">
          <p className="m-0 text-gray-600">
            Redirecting to Results in {countdown} seconds...
          </p>
        </div>
      )}
    </section>
  );
};

export default SurveyStep4;
