import { useTranslation } from "react-i18next";
const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <section
      className={`text-[13px] text-gray_1 text-right py-6 font-inter font-medium dark:text-reverted_gray dark:text-left${
        props.position === "static"
          ? " dark:bg-primary"
          : " lg:fixed bottom-0 left-0 w-screen"
      }`}
    >
      <div className="container mx-auto">
        {t("footer.copyright")} <span className="icon-ssl ml-4"></span>{" "}
        {t("footer.trust")}
        <a href="http://veemind.com/impressum/">{t("footer.impressum")}</a>
      </div>
    </section>
  );
};

export default Footer;
