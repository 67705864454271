import { useTranslation } from "react-i18next";
const imageUrl = process.env.REACT_APP_IMAGE_URL;
const SurveyStep2 = ({
  surveyData,
  submitSurveyHandler,
  surveyAnswers,
  gatherSurveyAnswers,
  categories,
  currentCategory,
  setCurrentCategory,
}) => {
  const { t } = useTranslation();
  const selectOptions = [
    "almost never",
    "rarely",
    "sometimes",
    "frequently",
    "often",
    "almost always",
  ];
  currentCategory = currentCategory ?? 0;
  var show_next_button = true;
  const current_category_image = surveyData
    ?.filter(
      (filtertype) =>
        filtertype.type !== "Input" &&
        filtertype.categories[0].title === categories[currentCategory]
    )
    .map((question) => question.categories[0].category_image.url);
  var current_questions = 0;
  const SurveyQuestion = ({ item, count }) => (
    <li
      data-count={count + 1}
      className={
        "question-item" +
        (item.categories[0].title !== categories[currentCategory]
          ? " hidden"
          : "")
      }
    >
      <h3>{item.text}</h3>
      {/* response types defined by question */}
      {item.type === "Scale" && (
        <div className="dot-select">
          {selectOptions.map((selection, ind) => (
            <span
              key={ind}
              onClick={() => gatherSurveyAnswers(item, selection)}
              className={`dot-select-option ${
                surveyAnswers[item.id] === selection && "before:!bg-secondary"
              }`}
            >
              {t(selection)}
              {/*Translate only text not value */}
            </span>
          ))}
        </div>
      )}
    </li>
  );

  return (
    <section className="container flex flex-wrap py-[85px] mx-auto">
      <div
        className="step-banner-vertical hidden lg:block lg:sticky lg:top-[86px]"
        style={{
          backgroundImage: "url(" + imageUrl + current_category_image[0] + ")",
        }}
      ></div>
      <div className="lg:w-7/12 w-full lg:pl-[80px]">
        <h2 className="title-primary mb-2">{t("step2.title")}</h2>
        <p className="subtitle mb-[70px]">{t("step2.description")}</p>
        <ul className="questions-wrapper">
          {surveyData.map((question, ind) => {
            var category_key = Object.keys(categories).filter(
              (key) => categories[key] === question.categories[0].title
            )[0];
            if (currentCategory >= category_key) current_questions++;
            if (
              surveyAnswers[question.id] === undefined &&
              parseInt(currentCategory) === parseInt(category_key)
            )
              show_next_button = false;
            return (
              <SurveyQuestion
                key={question.id}
                item={{ ...question, id: question.id }}
                count={ind}
              />
            );
          })}
        </ul>
        <div className="text-center w-full text-primary">
          <button
            className={
              "button text-primary mr-6 float-left step-button disabled:opacity-20 " +
              (currentCategory === 0 ? " invisible" : "")
            }
            /*disabled={
            !show_next_button?true:false
          }*/
            onClick={() => {
              setCurrentCategory((prior) => {
                return prior > 0 ? prior - 1 : 0;
              });
              window.scrollTo(0, 0);
            }}
          >
            {"<"}
          </button>
          {current_questions}
          <div className="progress-bar bg-[#D1D1D1] lg:max-w-[60%] max-w-[calc(100%-190px)] inline-block mx-3 my-4 align-middle">
            <span
              style={{
                width: `calc(${current_questions}*100%/${surveyData.length})`,
              }}
              className="!bg-secondary"
            ></span>
          </div>
          {surveyData.length}
          <button
            className={
              "button text-primary ml-6 float-right step-button disabled:opacity-20" +
              (currentCategory === categories.length - 1 ? " invisible" : "")
            }
            disabled={!show_next_button ? true : false}
            onClick={() => {
              setCurrentCategory((prior) => {
                var value = prior === undefined || prior === null ? 0 : prior;
                return value < categories.length - 1
                  ? value + 1
                  : categories.length - 1;
              });
              submitSurveyHandler({
                final_step: false,
                save_per_category: true,
              });
              window.scrollTo(0, 0);
            }}
          >
            {">"}
          </button>
          {current_questions === surveyData.length && (
            <button
              disabled={
                surveyData.filter((question) =>
                  (question.type === "Input" ||
                    Object.keys(surveyAnswers).some(
                      (key) => Number(key) === Number(question.id)
                    )) &&
                  show_next_button
                    ? false
                    : true
                ).length
              }
              className="disabled:opacity-20 button bg-secondary border-0 xl:w-[335px] lg:w-[280px] w-full text-black py-4 font-medium my-5"
              onClick={() => {
                submitSurveyHandler({});
                // window.scrollTo(0, 0);
              }}
            >
              {t("step2.cta")}
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default SurveyStep2;
