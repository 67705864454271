const PartnerHeader = ({ company_logo, company_name }) => {
  const renderedlogo = company_logo;

  return (
          <header className={`lg:fixed lg:top-0 lg:left-5 bg-white lg:z-10`}>
          <div className="container mx-auto lg:py-6 py-4 lg:flex lg:flex-row font-base">
        <div className="basis-1/3 self-center">
          <div className="flex flex-col items-center w-[100px]">
            {renderedlogo && <img src={renderedlogo} alt="" />}
            <div className="text-xs pt-2">{company_name}</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default PartnerHeader;
